import React from 'react'
import type { AppNavigationProps } from 'components/Page/NavigationApp/types'
import { AuthenticationKind } from 'constants/authentication'
import NavigationBarContent from 'redesign/components/organisms/NavigationBarContent'
import { isNative } from 'utils/platform'
import { useNavigationBar } from './useNavigationBar'

const NavigationBar: React.FC<AppNavigationProps> = ({ showSidePanel, hideSidePanel, isMenuActive }) => {
  const { auth, DashboardIcon, mainNavigation, value, onNavItemClick } = useNavigationBar({
    hideSidePanel,
  })

  if (auth < AuthenticationKind.AUTHENTICATED || !isNative() || isMenuActive) {
    return null
  }

  return (
    <NavigationBarContent
      DashboardIcon={DashboardIcon}
      mainNavigation={mainNavigation}
      value={value}
      onNavItemClick={onNavItemClick}
      showSidePanel={showSidePanel}
    />
  )
}

export default NavigationBar
