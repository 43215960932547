import { useMemo, useState } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getCalendarMonths, getCalendarYears } from 'redesign/shared/date/utils'
import { newDateWithoutTime } from 'utils/date'
import { WheelPickerColumnProps } from '../../atoms/WheelPickerColumn/types'
import { DATE_PICKER_DEFAULT_VALUES } from './constants'
import type { UseDatePicker } from './types'

export const useDatePicker = ({ onChange, value, minDate, maxDate }: UseDatePicker) => {
  const { locale } = useFavurTranslation()
  const [open, setOpen] = useState(false)
  const monthOptions = getCalendarMonths({ locale })
  const yearOptions = getCalendarYears({
    startYear: minDate?.getFullYear(),
    endYear: maxDate?.getFullYear(),
  })
  const currentYear = useMemo(() => newDateWithoutTime().getFullYear(), [])
  const defaultYearValue =
    yearOptions.find((option) => option.label === `${currentYear}`)?.value ?? yearOptions[yearOptions.length - 1].value
  const currentMonth = useMemo(() => newDateWithoutTime().getMonth(), [])
  const defaultMonthValue = monthOptions[currentMonth].value
  const [monthColumnValue, setMonthColumnValue] = useState(defaultMonthValue)
  const [yearColumnValue, setYearColumnValue] = useState(defaultYearValue)
  const [displayedFieldValue, setDisplayedFieldValue] = useState(DATE_PICKER_DEFAULT_VALUES.label)
  const monthOptionProps: WheelPickerColumnProps = useMemo(
    () => ({
      options: monthOptions,
      value: monthColumnValue,
      onChange: (changeValue: number) => {
        setMonthColumnValue(changeValue)
        setDisplayedFieldValue(`${monthOptions[changeValue].shortLabel} ${yearOptions[yearColumnValue].label}`)
      },
    }),
    [monthColumnValue, monthOptions, yearColumnValue, yearOptions],
  )
  const yearOptionProps: WheelPickerColumnProps = useMemo(
    () => ({
      options: yearOptions,
      value: yearColumnValue,
      onChange: (changeValue: number) => {
        setYearColumnValue(changeValue)
        setDisplayedFieldValue(`${monthOptions[monthColumnValue].shortLabel} ${yearOptions[changeValue].label}`)
      },
    }),
    [monthColumnValue, monthOptions, yearColumnValue, yearOptions],
  )
  const onClose = () => {
    setDisplayedFieldValue(
      value.date ? `${monthOptions[value.monthValue].shortLabel} ${yearOptions[value.yearValue].label}` : '',
    )
    setYearColumnValue(value.date ? value.yearValue : defaultYearValue)
    setMonthColumnValue(value.date ? value.monthValue : defaultMonthValue)
    setOpen(false)
  }
  const onClick = () => {
    setOpen(true)
    setDisplayedFieldValue(`${monthOptions[monthColumnValue].shortLabel} ${yearOptions[yearColumnValue].label}`)
  }
  const onChangeValue = () => {
    onChange({
      label: `${monthOptions[monthColumnValue].label} ${yearOptions[yearColumnValue].label}`,
      monthValue: monthColumnValue,
      yearValue: yearColumnValue,
      date: newDateWithoutTime(`${monthOptions[monthColumnValue].label} ${yearOptions[yearColumnValue].label}`),
    })
    setDisplayedFieldValue(`${monthOptions[monthColumnValue].shortLabel} ${yearOptions[yearColumnValue].label}`)
    setOpen(false)
  }

  return {
    open,
    setOpen,
    onClose,
    monthOptionProps,
    yearOptionProps,
    displayedFieldValue,
    onClick,
    onChangeValue,
    value,
  }
}
