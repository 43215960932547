import React, { useMemo } from 'react'
import { Button, IconButton, useTheme } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { CloseIcon, MenuIcon } from 'redesign/components/atoms/Icon'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import { FavurLogoWhite } from 'redesign/components/atoms/Illustrations'
import routes from 'services/RoutesProvider/routes'
import { MainContainer } from './styles'
import type { HamburgerHeaderProps } from './types'

const HamburgerHeader: React.FC<HamburgerHeaderProps> = ({
  toggleMenu,
  isMenuOpen = false,
  showHamburger = true,
  showLogo = true,
}) => {
  const theme = useTheme()
  const history = useHistory()

  const Icon = useMemo(() => (isMenuOpen ? CloseIcon : MenuIcon), [isMenuOpen])

  return (
    <MainContainer showLogo={showLogo}>
      {showLogo && (
        <Button
          size="small"
          onClick={() => {
            history.push(routes.dashboard)
          }}
        >
          <FavurLogoWhite />
        </Button>
      )}
      {showHamburger && (
        <IconButton onClick={toggleMenu} data-testid="navigation-icon" size="large">
          <Icon size={ICON_SIZE.large} color={theme.palette.common.white} />
        </IconButton>
      )}
    </MainContainer>
  )
}

export default HamburgerHeader
