import React from 'react'
import PinLogin from 'pages/PinLogin'
import DocumentsPage from './DocumentsPage'

const PinSecureDocumentsPage = () => (
  <PinLogin>
    <DocumentsPage />
  </PinLogin>
)

export default PinSecureDocumentsPage
