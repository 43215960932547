export const seenTutorialBase = 'SEEN_TUTORIAL_'

export const tutorialTypes = {
  absencePlanFrontliner: 'absenceplan-frontliner',
  absencePlanManager: 'absenceplan-manager',
  absenceRequestFrontliner: 'absence-request-frontliner',
  absenceRequestManager: 'absence-request-manager',
  resignation: 'resignation',
  secureConnection: 'secure-connection',
  shiftPlan: 'shiftplan',
  socialEngineering: 'social-engineering',
  workTimeControlFrontliner: 'work-time-control-frontliner',
  workTimeControlManager: 'work-time-control-manager',
}

export const tutorialNames = {
  ABSENCE_PLAN_FRONTLINER: 'absenceplanFrontliner',
  ABSENCE_PLAN_MANAGER: 'absenceplanManager',
  ABSENCE_REQUEST_FRONTLINER: 'absenceRequestFrontliner',
  ABSENCE_REQUEST_MANAGER: 'absenceRequestManager',
  RESIGNATION: 'resignation',
  SECURE_CONNECTION: 'secureConnection',
  SHIFT_PLAN: 'shiftplan',
  SOCIAL_ENGINEERING: 'socialEngineering',
  WORK_TIME_CONTROL_FRONTLINER: 'workTimeControlFrontliner',
  WORK_TIME_CONTROL_MANAGER: 'workTimeControlManager',
}

// First tutorial must be the own tutorial
export const getRelatedTutorials = (tutorialName: string) => {
  switch (tutorialName) {
    case tutorialTypes.absencePlanFrontliner:
      return [tutorialNames.ABSENCE_PLAN_FRONTLINER, tutorialNames.ABSENCE_PLAN_MANAGER]
    case tutorialTypes.absencePlanManager:
      return [tutorialNames.ABSENCE_PLAN_MANAGER, tutorialNames.ABSENCE_PLAN_FRONTLINER]
    case tutorialTypes.absenceRequestFrontliner:
      return [tutorialNames.ABSENCE_REQUEST_FRONTLINER]
    case tutorialTypes.absenceRequestManager:
      return [tutorialNames.ABSENCE_REQUEST_MANAGER]
    case tutorialTypes.resignation:
      return [tutorialNames.RESIGNATION]
    case tutorialTypes.secureConnection:
      return [tutorialNames.SECURE_CONNECTION]
    case tutorialTypes.socialEngineering:
      return [tutorialNames.SOCIAL_ENGINEERING]
    case tutorialTypes.shiftPlan:
      return [tutorialNames.SHIFT_PLAN]
    case tutorialTypes.workTimeControlFrontliner:
      return [tutorialNames.WORK_TIME_CONTROL_FRONTLINER]
    case tutorialTypes.workTimeControlManager:
      return [tutorialNames.WORK_TIME_CONTROL_MANAGER]
    default:
      return []
  }
}
