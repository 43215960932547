export const MENU_CONTAINER_MOTION = {
  hidden: { opacity: 0, x: -100 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { staggerChildren: 0.4, ease: 'easeOut' },
  },
}

export const GROUP_HIDDEN_MOTION = { opacity: 0, x: -100 }

export const PRIMARY_GROUP_MOTION = {
  hidden: GROUP_HIDDEN_MOTION,
  visible: { opacity: 1, x: 0 },
}

export const SECONDARY_GROUP_MOTION = {
  hidden: GROUP_HIDDEN_MOTION,
  visible: { opacity: 1, x: 0, transition: { delay: 0.5 } },
}

export const TERTIARY_GROUP_MOTION = {
  hidden: GROUP_HIDDEN_MOTION,
  visible: { opacity: 1, x: 0, transition: { delay: 0.6 } },
}
