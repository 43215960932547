import { styled } from '@mui/material'

export const HeaderContainer = styled('div')`
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme }) => theme.spacing(72)};
  padding: ${({ theme }) => theme.spacing(3, 0, 2, 0)};
  color: ${({ theme }) => theme.palette.common.white};
`

export const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.spacing(5)};
  height: ${({ theme }) => theme.spacing(5)};
`
