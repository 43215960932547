import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_TUTORIAL } from './constants'
import { IconPropsT } from './types'

const PhoneMail: React.FC<IconPropsT> = ({ fill, size = ICON_SIZE_TUTORIAL }) => {
  return (
    <Svg size={size} fill={fill} viewBox="0 0 80 80">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.524 4.833H9.732a9.269 9.269 0 0 0-6.645 2.835 9.826 9.826 0 0 0-2.754 6.84v43.154A9.82 9.82 0 0 0 3.087 64.5a9.264 9.264 0 0 0 6.645 2.832h17.634A9.262 9.262 0 0 0 34.01 64.5a9.818 9.818 0 0 0 2.752-6.839V40.25c0-1.15-.906-2.083-2.024-2.083-1.118 0-2.024.932-2.024 2.083v10.417H4.831c-.156 0-.306.022-.45.065V14.508c0-1.46.565-2.861 1.568-3.894A5.279 5.279 0 0 1 9.733 9h6.79c1.119 0 2.025-.933 2.025-2.083 0-1.15-.906-2.084-2.024-2.084ZM4.38 54.768v2.892c0 1.46.565 2.862 1.568 3.894a5.275 5.275 0 0 0 3.783 1.613h17.634c1.419 0 2.779-.58 3.782-1.613a5.589 5.589 0 0 0 1.566-3.893v-2.828H4.831c-.156 0-.306-.023-.45-.065Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.633 2.41A5.794 5.794 0 0 1 27.767.667h23.386c1.551 0 3.038.627 4.135 1.743A6.008 6.008 0 0 1 57 6.62v15.873a6.008 6.008 0 0 1-1.712 4.209 5.795 5.795 0 0 1-4.135 1.743H27.767a5.794 5.794 0 0 1-4.134-1.743 6.007 6.007 0 0 1-1.712-4.209V6.62c0-1.579.616-3.093 1.712-4.209Zm4.134 2.225c-.517 0-1.012.209-1.378.581a1.981 1.981 0 0 0-.333.454l12.37 7.864a1.926 1.926 0 0 0 2.067 0L52.865 5.67a1.982 1.982 0 0 0-.334-.454 1.932 1.932 0 0 0-1.378-.581H27.767Zm25.335 5.562L42.56 16.9a5.773 5.773 0 0 1-3.099.904 5.77 5.77 0 0 1-3.098-.904l-10.544-6.703v12.295c0 .526.206 1.03.571 1.403.366.372.861.581 1.378.581h23.386c.517 0 1.013-.209 1.379-.581.365-.372.57-.877.57-1.403V10.197Z"
      />
    </Svg>
  )
}

export default PhoneMail
