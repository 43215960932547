import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const HomeIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color,
  size = ICON_SIZE.medium,
  isFilled,
  fillOpacity,
}) => {
  const path = isFilled ? (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.559 2.145a.732.732 0 0 1 .88 0L21.394 8.9c.02.015.039.03.057.047a1.704 1.704 0 0 1 .545 1.197v10.321A1.54 1.54 0 0 1 20.46 22h-5.375a.732.732 0 0 1-.731-.732v-4.325a2.358 2.358 0 1 0-4.716 0v4.323a.732.732 0 0 1-.731.731H3.53a1.54 1.54 0 0 1-1.535-1.535V10.144l.001-.04A1.701 1.701 0 0 1 2.597 8.9l8.962-6.755z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  ) : (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.559 2.145a.732.732 0 0 1 .88 0L21.394 8.9c.02.015.039.03.057.047a1.704 1.704 0 0 1 .545 1.197v10.321A1.54 1.54 0 0 1 20.46 22h-5.375a.732.732 0 0 1-.731-.732v-4.325a2.358 2.358 0 1 0-4.716 0v4.323a.732.732 0 0 1-.731.731H3.53a1.54 1.54 0 0 1-1.535-1.535V10.144l.001-.04A1.701 1.701 0 0 1 2.597 8.9l8.962-6.755zm-8.042 7.894a.238.238 0 0 0-.058.132v10.287c0 .042.034.075.075.076h4.64v-3.591a3.821 3.821 0 0 1 7.642 0v3.593h4.64a.077.077 0 0 0 .076-.076V10.171a.241.241 0 0 0-.06-.132L12 3.645 3.517 10.04z"
      fill={color}
    />
  )

  return (
    <SvgWrapper viewBox={viewBox} size={size}>
      {path}
    </SvgWrapper>
  )
}

export default HomeIcon
