import React from 'react'
import { useTheme } from '@mui/material'
import { CalendarIcon } from 'redesign/components/atoms/Icon'
import { TextField } from './styles'
import type { DatePickerInputProps } from './types'

const DatePicker: React.FC<DatePickerInputProps> = ({ label, onClick, testId, value, focused, disabled, error }) => {
  const theme = useTheme()

  return (
    <TextField
      helperText={error}
      error={Boolean(error)}
      disabled={disabled}
      focused={focused}
      data-testid={testId}
      onClick={() => {
        if (disabled) return

        onClick()
      }}
      label={label}
      InputProps={{
        endAdornment: <CalendarIcon color={focused ? theme.palette.primary.main : 'currentColor'} fillOpacity="0.6" />,
        readOnly: true,
      }}
      InputLabelProps={{ shrink: true }}
      value={value}
    />
  )
}
export default DatePicker
