export const HEADLINE_LEVELS = {
  first: 'first',
  second: 'second',
  third: 'third',
} as const

export type HeadlineProps = {
  level?: keyof typeof HEADLINE_LEVELS
  text: string
  testId?: string
  noBottomSpacing?: boolean
  horizontalSpacing?: number
}
