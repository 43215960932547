import { isToday } from 'date-fns'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDate, getWeekDay } from 'redesign/shared/date/utils'
import { newDateWithoutTime } from 'utils/date'
import { ProcessShiftDate } from './types'

export const getProcessedShiftDate = ({ date, locale, t }: ProcessShiftDate) => {
  const dateObject = newDateWithoutTime(date)
  const dateIsToday = isToday(dateObject)
  const dayOfWeek = getWeekDay({ date: dateObject, locale })
  const [dayOfMonth, month] = formatDate({
    date: dateObject,
    dateFormat: DATE_FORMAT_VARIANTS.shortWithoutWeekday,
    locale,
    t,
  }).split(' ')

  return {
    dateIsToday,
    dayOfWeek,
    dayOfMonth,
    month,
  }
}
