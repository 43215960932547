import { useContext, useCallback, useEffect } from 'react'
import useNotificationCounts from 'hooks/useNotificationCounts'
import useNotificationsPaginated from 'hooks/useNotificationsPaginated'
import useRolesViews from 'hooks/useRolesViews'
import { acknowledgeOneMutation, acknowledgeAllMutation } from 'pages/Notifications/queries'
import { PAGE_KEYS } from 'redesign/pages/Notifications/constants'
import SubheaderTabContext from 'redesign/services/SubheaderTabs/context'
import { Notification as NotificationT } from 'shared/graphql/graphql'
import type { MutationResult } from 'types'
import { useMutation } from '@apollo/client'

export const useNotificationsList = () => {
  const [acknowledgeOne] = useMutation<MutationResult>(acknowledgeOneMutation)
  const [acknowledgeAll] = useMutation<MutationResult>(acknowledgeAllMutation)
  const { hasOfficeView: hasManagerView } = useRolesViews()
  const { counts, refetch: refetchCounts, loading: loadingCounts } = useNotificationCounts()
  const { selectedTabName, setCounts } = useContext(SubheaderTabContext)
  const isManager = hasManagerView && selectedTabName === PAGE_KEYS.supervisor
  const {
    notifications,
    loading: loadingNotifications,
    hasMoreElements,
    totalCount,
    loadMore,
    reload,
  } = useNotificationsPaginated(isManager)
  const showLoadMoreButton = (totalCount === undefined || hasMoreElements) && Boolean(notifications.length)
  const loading = loadingNotifications || loadingCounts
  const showAcknowledgeAll = !loading && isManager ? Boolean(counts.manager) : Boolean(counts.personal)

  const handleAcknowledgeAll = useCallback(async () => {
    await acknowledgeAll({ variables: { isManager } })
    refetchCounts()
    reload()
  }, [acknowledgeAll, isManager, refetchCounts, reload])

  const dismiss = useCallback(
    (dismissedNotification: NotificationT) =>
      acknowledgeOne({ variables: { notificationId: dismissedNotification.id } }),
    [acknowledgeOne],
  )

  useEffect(() => {
    setCounts(counts)
  }, [counts, setCounts])

  useEffect(() => {
    reload()
    // Eslint requires reload to be a dependency, but it would generate an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabName])

  return {
    counts,
    dismiss,
    handleAcknowledgeAll,
    hasManagerView,
    loading,
    loadMore,
    notifications,
    showLoadMoreButton,
    showAcknowledgeAll,
  }
}
