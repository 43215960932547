import { CalculateContentPadding } from './types'

export const calculateContentPadding = ({
  noPadding,
  hasFloatingButtons,
  paddingX,
  extraBottomMargin,
  theme,
}: CalculateContentPadding) => {
  if (noPadding) return 0

  const padX = theme.spacing(paddingX)
  const padTop = theme.spacing(3)
  const padBot = hasFloatingButtons
    ? `${theme.spacing(3.5 + extraBottomMargin)}`
    : `${theme.spacing(extraBottomMargin)}`

  return `${padTop} ${padX} ${padBot}`
}
