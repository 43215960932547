import React, { useMemo, useRef } from 'react'
import { Fade } from '@mui/material'
import { AuthenticationKind } from 'constants/authentication'
import HamburgerHeader from 'redesign/components/molecules/HamburgerHeader'
import SideMenuHeader from '../../Headers/SideMenuHeader'
import NavigationFooter from '../NavigationFooter'
import NavigationMenuOptions from '../NavigationMenuOptions'
import { Background, BottomScroll } from './styles'
import type { NavigationMenuProps } from './types'

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  appInfoData,
  auth,
  navigate,
  toggleAppInfoDialog,
  toggleDrawer,
  hideHamburger,
  showBackground,
  showAppInfo,
  navigationLinks,
  userFirstName,
  isNative,
  onNavigationGroupClick,
  navigationButtonCallbacks,
}) => {
  const loggedInAndShowHamburger = auth > AuthenticationKind.ANONYMOUS && !hideHamburger
  const scrollRef = useRef<HTMLDivElement>(null)

  const scroll = useMemo(
    () => navigate && (scrollRef.current?.scrollHeight ?? 0) - (scrollRef.current?.clientHeight ?? 0) > 0,
    [navigate],
  )

  return (
    <>
      {loggedInAndShowHamburger && (
        <Fade in={navigate} timeout={700}>
          <Background isVisible={showBackground} isNative={isNative}>
            {!isNative && <HamburgerHeader toggleMenu={toggleDrawer} isMenuOpen showLogo={false} />}
            <SideMenuHeader userFirstName={userFirstName} />
            {scroll && <BottomScroll />}
            <NavigationMenuOptions
              appInfoData={appInfoData}
              showAppInfo={showAppInfo}
              toggleAppInfoDialog={toggleAppInfoDialog}
              navigationLinks={navigationLinks}
              onNavigationGroupClick={onNavigationGroupClick}
              navigationButtonCallbacks={navigationButtonCallbacks}
            />
            {isNative && <NavigationFooter toggleDrawer={toggleDrawer} />}
          </Background>
        </Fade>
      )}
    </>
  )
}

export default NavigationMenu
