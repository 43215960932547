import { ANDROID_PLAY_STORE_LINK, IOS_APP_STORE_LINK } from 'constants/appStores'
import { isAndroidNative, isIphoneNative, isNativeNoOverride } from 'utils/platform'
import { GetNotificationData } from '../types'

export const getEmptyNotification = ({ t }: GetNotificationData) => {
  const goToAppStore = () => {
    if (isNativeNoOverride()) {
      if (isIphoneNative()) {
        window.location.href = IOS_APP_STORE_LINK
      }
      if (isAndroidNative()) {
        window.location.href = ANDROID_PLAY_STORE_LINK
      }
    }
    return ANDROID_PLAY_STORE_LINK
  }

  return {
    title: t('notification.empty.title'),
    body: t('notification.empty.body'),
    route: goToAppStore(),
    outsideLink: true,
  }
}
