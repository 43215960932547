import React, { useCallback, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import { createShareUserDataWokflowsQuery } from 'pages/RequestEmployeeData/queries'
import { CreateShareUserDataResult, RequestEmployeeDataModeT } from 'pages/RequestEmployeeData/types'
import { AuthPersonIdAndPermissions, PersonT, TenantT } from 'types'
import { useMutation } from '@apollo/client'
import ErrorListDialog from '../ErrorListDialog'
import { classes } from './styles'

const getPersonWithPermissions = (personList: AuthPersonIdAndPermissions[] | undefined, tenant: TenantT) =>
  personList &&
  personList.find(
    (person) =>
      (person.permissions.workflowShareUserData && `${person.tenantId}` === `${tenant.id}`) ||
      (person.permissions.workflowShareUserDataGlobal === true && person.ownerId === tenant.ownerId),
  )?.id

const matchErrorPersons = (personList: PersonT[], errorIdList: number[]): PersonT[] =>
  personList.filter((person) => person.id && errorIdList.includes(person.id))

interface IConfirmationDialogProps {
  dialogOpen: boolean
  setDialogOpen: (open: boolean) => void
  persons: PersonT[]
  mode: RequestEmployeeDataModeT
  tenant: TenantT
  setProcessSuccess: (success: boolean) => void
}

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
  dialogOpen,
  setDialogOpen,
  persons,
  mode,
  tenant,
  setProcessSuccess,
}) => {
  const { t } = useFavurTranslation()
  const { persons: userPersons } = useRolesViews()
  const [createShareUserDataMutation] = useMutation<CreateShareUserDataResult>(createShareUserDataWokflowsQuery)
  const [errorPersonList, setErrorPersonList] = useState<PersonT[]>([])
  const onSubmit = useCallback(() => {
    createShareUserDataMutation({
      variables: {
        personIds: persons.map((person) => person.id as number),
        triggeredByPersonId: getPersonWithPermissions(userPersons, tenant),
      },
    })
      .then((mutationResult) => {
        const personsWithErrors = mutationResult?.data?.createShareUserDataRequest?.personsWithErrors
        if (!personsWithErrors || personsWithErrors.length > 0) {
          personsWithErrors && setErrorPersonList(matchErrorPersons(persons, personsWithErrors))
        } else setProcessSuccess(true)
      })
      .catch((_e) => setErrorPersonList(persons))
  }, [createShareUserDataMutation, persons, userPersons, tenant, setProcessSuccess])

  const dialogActions = (
    <Box>
      <Button variant="text" onClick={() => setDialogOpen(false)} color="secondary">
        {t('common.cancel')}
      </Button>
      <Button variant="text" onClick={() => onSubmit()} color="secondary" data-testid="create-workflows">
        {t('requestEmployeeData.common.request')}
      </Button>
    </Box>
  )
  return (
    <Dialog
      open={dialogOpen}
      title={t('requestEmployeeData.common.overTitle')}
      actions={dialogActions}
      data-testid="confirmation-dialog"
    >
      <ErrorListDialog dialogOpen={errorPersonList.length > 0} personList={errorPersonList} />
      <Typography variant="body2" sx={classes.body1}>
        {t(
          mode === 'global'
            ? 'requestEmployeeData.confirmDialog.globalBody1'
            : 'requestEmployeeData.confirmDialog.singleBody1',
          { tenant: tenant.name },
        )}
      </Typography>
      <Typography variant="body2" sx={classes.body2}>
        {t(
          mode === 'global'
            ? 'requestEmployeeData.confirmDialog.globalBody2'
            : 'requestEmployeeData.confirmDialog.singleBody2',
          { numEmployees: persons.length },
        )}
      </Typography>
    </Dialog>
  )
}

export default ConfirmationDialog
