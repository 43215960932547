import { styled, Chip as MuiChip } from '@mui/material'

export const Chip = styled(MuiChip)<{ selected?: boolean; disabled?: boolean }>`
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
  align-items: center;
  height: ${({ theme }) => theme.spacing(5)};
  width: fit-content;
  opacity: ${({ disabled }) => (disabled ? 0.38 : 1)};
  background-color: ${({ theme, selected }) => (selected ? theme.palette.common.greyDark : 'inherit')};
  border: ${({ theme, selected }) =>
    `${theme.spacing(0.1)} solid ${!selected ? theme.palette.common.greyMid : theme.palette.common.greyDark}`};
  border-radius: ${({ theme }) => theme.spacing(12.5)};
  color: ${({ theme, selected }) => (selected ? theme.palette.common.greyLight : theme.palette.text.primary)};
  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.palette.grey[500] : theme.palette.action.hover} !important;
  }
  &:focus {
    background-color: ${({ theme, selected }) =>
      selected ? theme.palette.grey[600] : theme.palette.action.focus} !important;
  }
  & .MuiChip-deleteIcon {
    color: ${({ theme, selected }) => (selected ? theme.palette.common.greyLight : theme.palette.text.primary)};
    margin: 0;
  }
  & .MuiChip-label {
    padding: 0;
  }
`
