import React, { useContext, useMemo, useState, useEffect } from 'react'
import PageWithFilter from 'components/Filter/PageWithFilter'
import { FilterProvider } from 'components/Filter/context'
import { SimpleLoading } from 'components/LoadingIcon'
import { tasksArray } from 'constants/tasks'
import useBannerHighSecurityConnectionEnable from 'hooks/useBannerHighSecurityConnectionEnable'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import { RouteComponentProps } from 'react-router-dom'
import FlashMessagesContext from 'services/FlashMessages/context'
import { TaskTypeT, taskTypes } from 'shared/constants'
import TasksPageContent from './PageContent'
import TasksHeader from './components/TasksHeader'
import useFilterConfiguration from './hooks/useFilterConfiguration'
import type { TaskStateT } from './types'

const getUrlTaskState = (taskStateUrl: TaskStateT | undefined) => {
  return taskStateUrl && tasksArray.includes(taskStateUrl) ? taskStateUrl : tasksArray[0]
}

const getUrlTaskType = (): TaskTypeT | undefined => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const taskType = urlParams.get('task_type')
  return taskType &&
    Object.values(taskTypes)
      .map((wTaskType) => wTaskType.toString())
      .includes(taskType)
    ? (taskType as TaskTypeT)
    : undefined
}

const Tasks: React.FC<RouteComponentProps<{ taskstate?: TaskStateT }>> = ({ match }) => {
  const { loading: loadingBanner } = useBannerHighSecurityConnectionEnable()

  const { activeView, roles, persons } = useRolesViews()
  const { removeAll } = useContext(FlashMessagesContext)
  const { taskstate: taskStateUrl } = match.params
  const isOffice = activeView === roles.office
  const { configuration, loading: configLoading } = useFilterConfiguration(
    getUrlTaskState(taskStateUrl),
    getUrlTaskType(),
  )
  const { t } = useFavurTranslation()
  const [sideMenuOpen, setSideMenuOpen] = useState(false)

  const titleTrans = isOffice ? t('page.tasks.page.adminTitle') : t('page.tasks.page.title')

  const isShareUserDataManager = useMemo(() => persons?.some((person) => person.permissions.workflowShareUserData), [
    persons,
  ])

  useEffect(() => {
    removeAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showSideMenuButton = isOffice && (isShareUserDataManager as boolean)

  const headerComponent = (
    <TasksHeader
      titleTrans={titleTrans}
      sideMenuOpen={sideMenuOpen}
      setSideMenuOpen={setSideMenuOpen}
      showSideMenuButton={showSideMenuButton}
    />
  )

  if (configLoading || loadingBanner) {
    return (
      <PageWithFilter header={headerComponent} filterPageTitle={titleTrans}>
        <SimpleLoading />
      </PageWithFilter>
    )
  }

  return (
    <FilterProvider configuration={configuration} name="tasks">
      <TasksPageContent header={headerComponent} isOffice={isOffice} titleTrans={titleTrans} />
    </FilterProvider>
  )
}

export default Tasks
