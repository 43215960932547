import React from 'react'
import { Badge, IconButton, useTheme } from '@mui/material'
import { useHistory } from 'react-router'
import routes from 'services/RoutesProvider/routes'
import { Bell } from '../../Icon'
import { BADGE_MAX_VALUE } from './constants'
import { TNotificationsButton } from './types'

const NotificationsButton: React.FC<TNotificationsButton> = ({ totalNotifications }) => {
  const theme = useTheme()
  const history = useHistory()

  return (
    <IconButton onClick={() => history.push(routes.notifications)} data-testid="show-all-notifications-icon">
      <Badge variant="small" color="primary" badgeContent={totalNotifications} max={BADGE_MAX_VALUE}>
        <Bell color={theme.palette.primary.main} />
      </Badge>
    </IconButton>
  )
}

export default NotificationsButton
