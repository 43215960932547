import React from 'react'
import { useContext } from 'react'
import Tabs from 'redesign/components/organisms/Tabs'
import SubheaderTabContext from 'redesign/services/SubheaderTabs/context'
import { Container } from './styles'

const SubheaderTabs: React.FC = () => {
  const { selectedTabName, setSelectedTabName, pages } = useContext(SubheaderTabContext)

  return (
    <Container>
      <Tabs
        variant="fullWidth"
        pages={pages}
        color="primary"
        showTabPage={false}
        selectedTabName={selectedTabName}
        setSelectedTabName={setSelectedTabName}
        iconPosition="end"
      />
    </Container>
  )
}

export default SubheaderTabs
