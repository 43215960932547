import { styled } from '@mui/material'

export const MainContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showLogo',
})<{
  showLogo: boolean
}>`
  display: flex;
  justify-content: ${({ showLogo }) => (showLogo ? 'space-between' : 'right')};
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.hamburgerHeader};
  padding: ${({ theme }) => theme.spacing(0, 2)};
  height: ${({ theme }) => theme.spacing(8)};
`
