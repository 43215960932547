import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const AlertTriangle: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
}) => (
  <SvgWrapper viewBox={viewBox} size={size} fill="none">
    <path
      fill="white"
      d="M12 17.5a.375.375 0 0 1 0-.75M12 17.5a.375.375 0 0 0 0-.75"
      stroke={color}
      strokeWidth="1.5"
    />
    <path d="M12 14.75v-5.5" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path
      d="M13.621 3.76a1.806 1.806 0 0 0-3.242 0L2.906 19.058a1.52 1.52 0 0 0 1.366 2.192h15.456a1.52 1.52 0 0 0 1.366-2.192L13.621 3.76z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgWrapper>
)

export default AlertTriangle
