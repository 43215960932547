import { ROLE_VIEWS } from 'constants/roles'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDateRange } from 'redesign/shared/date/utils'
import routes from 'services/RoutesProvider/routes'
import { AbsenceRequestRequestDeletionNotification } from 'shared/graphql/graphql'
import { newDateWithoutTime } from 'utils/date'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getAbsenceRequestDeletionData = ({ notification, t, locale }: GetNotificationData) => {
  const {
    taskUuid,
    frontlinerNameShort,
    dateFrom: notificationDateFrom,
    dateTo: notificationDateTo,
  } = notification.data as AbsenceRequestRequestDeletionNotification
  const { title } = notificationGlosssaryKeys.absenceRequestRequestDeletion
  const dateFormat = DATE_FORMAT_VARIANTS.shortWithWeekday

  const body = formatDateRange({
    dates: {
      startDate: newDateWithoutTime(notificationDateFrom),
      endDate: newDateWithoutTime(notificationDateTo),
    },
    dateFormat,
    locale,
    t,
  })

  return {
    title: t(title, { frontlinerNameShort }),
    body,
    route: `${routes.absenceRequestDetail}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
