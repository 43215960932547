import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import type { FiltersFooterProps } from './types'

const FiltersFooter: React.FC<FiltersFooterProps> = ({ isPersonsFilter, onClick }) => {
  const { t } = useFavurTranslation()
  const translationKey = isPersonsFilter ? 'filter.persons.list.confirm' : 'userDataWorkflow.button.save'

  return (
    <Button variant="contained" fullWidth onClick={onClick}>
      {t(translationKey)}
    </Button>
  )
}

export default FiltersFooter
