import React from 'react'
import DatePicker from '../../DatePicker'
import { Container } from './styles'
import type { DateRangeFilterProps } from './types'
import { useDateRangeFilter } from './useDateRangeFilter'

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({ configuration }) => {
  const {
    endDateOnChange,
    error,
    fromDateValues,
    fromDateName,
    maxDate,
    minDate,
    startDateOnChange,
    toDateName,
    toDateValues,
  } = useDateRangeFilter({ configuration })

  return (
    <Container>
      <DatePicker
        testId="filter-date-range"
        error={error}
        label={fromDateName}
        value={fromDateValues}
        onChange={startDateOnChange}
        minDate={minDate}
        maxDate={maxDate}
      />
      <DatePicker
        error={error}
        label={toDateName}
        value={toDateValues}
        onChange={endDateOnChange}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Container>
  )
}

export default DateRangeFilter
