import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const CheckMark: React.FC<IconProps> = ({
  color = 'currentColor',
  size = ICON_SIZE.medium,
  viewBox = ICON_VIEWBOX.medium,
  isFilled,
  fillOpacity,
}) => {
  const path = isFilled ? (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10 0-1.694-.421-3.29-1.164-4.687a.78.78 0 0 1-.09.106l-8.048 7.942a2.18 2.18 0 0 1-3.424-.472L7.229 11.3a.77.77 0 1 1 1.337-.762l2.044 3.588a.641.641 0 0 0 1.008.138l8.047-7.94a.765.765 0 0 1 .419-.213A9.986 9.986 0 0 0 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  ) : (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C13.73 3.75 15.3336 4.28168 16.6591 5.19052C17.0008 5.42475 17.4676 5.33769 17.7018 4.99607C17.9361 4.65445 17.849 4.18762 17.5074 3.95339C15.9401 2.87879 14.0423 2.25 12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 11.5977 21.7256 11.2007 21.6781 10.8106C21.6281 10.3994 21.2542 10.1067 20.843 10.1567C20.4318 10.2068 20.139 10.5807 20.1891 10.9919C20.2293 11.322 20.25 11.6584 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12ZM20.5268 7.53383C20.8216 7.24288 20.8248 6.76802 20.5338 6.47319C20.2429 6.17837 19.768 6.17522 19.4732 6.46617L11.6272 14.2089C11.3349 14.4974 10.8486 14.4303 10.6452 14.0736L8.65158 10.576C8.44646 10.2161 7.98846 10.0907 7.6286 10.2958C7.26874 10.5009 7.1433 10.9589 7.34842 11.3188L9.34205 14.8164C10.0335 16.0294 11.687 16.2573 12.6808 15.2766L20.5268 7.53383Z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  )

  return (
    <SvgWrapper viewBox={viewBox} size={size} fill="none">
      {path}
    </SvgWrapper>
  )
}

export default CheckMark
