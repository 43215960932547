import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { ICON_SIZE } from '../../Icon/constants'
import Tag from '../Tag'
import { TAG_SIZE } from '../constants'
import { TagContent } from '../styles'
import { ABSENCE_ICON, ABSENCE_TYPE } from './constants'
import type { AbsenceTagProps } from './types'

const AbsenceTag: React.FC<AbsenceTagProps> = ({
  tagSize = TAG_SIZE.large,
  text,
  absenceType = ABSENCE_TYPE.fullDay,
  fullWidth = false,
}) => {
  const AbsenceIcon = ABSENCE_ICON[absenceType]
  const theme = useTheme()

  return (
    <Tag
      noPadding={Boolean(text)}
      borderColor={theme.palette.common?.cardBorder}
      tagSize={tagSize}
      fullWidth={fullWidth}
    >
      <TagContent>
        <AbsenceIcon color={theme.palette.common?.greyMid} size={ICON_SIZE.small} />
        {text && (
          <Typography color={theme.palette.text.secondary} variant="chipSmall" noWrap>
            {text}
          </Typography>
        )}
      </TagContent>
    </Tag>
  )
}

export default AbsenceTag
