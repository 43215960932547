import React from 'react'
import { Container, Puller } from './styles'

const DrawerPuller: React.FC = () => (
  <Container>
    <Puller />
  </Container>
)

export default DrawerPuller
