import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const TimerIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
  isFilled,
}) => {
  const path = isFilled ? (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25 2a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-1.25v2.532a8.75 8.75 0 0 1 4.513 1.728l.714-.714a.75.75 0 0 1 1.22-.826l.333.332v.002l.334.332a.75.75 0 0 1-.826 1.22l-.657.657a8.749 8.749 0 1 1-7.131-2.73V2.75H10A.75.75 0 0 1 9.25 2Zm-1.086 8.42a.75.75 0 0 1 1.06.054l3.332 3.69a.75.75 0 1 1-1.112 1.005L8.11 11.48a.75.75 0 0 1 .054-1.059Z"
      fill={color}
    />
  ) : (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25 2a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-1.25v2.532a8.75 8.75 0 0 1 4.513 1.728l.714-.714a.75.75 0 0 1 1.22-.826l.333.332v.002l.334.332a.75.75 0 0 1-.826 1.22l-.657.657a8.749 8.749 0 1 1-7.131-2.73V2.75H10A.75.75 0 0 1 9.25 2ZM12 6.75a7.25 7.25 0 1 0 0 14.5 7.25 7.25 0 0 0 0-14.5Zm-3.836 3.67a.75.75 0 0 1 1.06.054l3.332 3.69a.75.75 0 1 1-1.112 1.005L8.11 11.48a.75.75 0 0 1 .054-1.059Z"
      fill={color}
    />
  )

  return (
    <SvgWrapper viewBox={viewBox} size={size}>
      {path}
    </SvgWrapper>
  )
}

export default TimerIcon
