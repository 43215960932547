import { styled, Typography } from '@mui/material'

export const MainContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  width: fit-content;
  overflow: hidden;
`
export const StartText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const EndText = styled(Typography)`
  white-space: nowrap;
  min-width: fit-content;
`
