import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_TUTORIAL } from './constants'
import { IconPropsT } from './types'

const PhoneSMS: React.FC<IconPropsT> = ({ fill, size = ICON_SIZE_TUTORIAL }) => {
  return (
    <Svg size={size} fill={fill} viewBox="0 0 80 80">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.333 4.833h-6.991a9.68 9.68 0 0 0-9.675 9.675v43.154a9.675 9.675 0 0 0 9.675 9.671h18.152a9.672 9.672 0 0 0 9.673-9.672V40.25a2.083 2.083 0 0 0-4.167 0v10.417H5.296c-.16 0-.315.022-.463.065V14.508A5.511 5.511 0 0 1 10.343 9h6.99a2.083 2.083 0 1 0 0-4.167Zm-12.5 49.935v2.892a5.51 5.51 0 0 0 5.509 5.507h18.152A5.505 5.505 0 0 0 34 57.66v-2.828H5.296c-.16 0-.315-.023-.463-.065ZM43.249 15.124a3.125 3.125 0 1 1 4.42 4.42 3.125 3.125 0 0 1-4.42-4.42ZM32.832 15.124a3.126 3.126 0 1 1 4.42 4.42 3.126 3.126 0 0 1-4.42-4.42ZM53.665 15.124a3.125 3.125 0 1 1 4.42 4.42 3.125 3.125 0 0 1-4.42-4.42Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.634 3.718A10.417 10.417 0 0 1 34 .667h22.917a10.417 10.417 0 0 1 10.416 10.416v12.5A10.416 10.416 0 0 1 56.917 34h-1.22l-9.807 9.806a2.083 2.083 0 0 1-3.557-1.473V34H34a10.417 10.417 0 0 1-10.417-10.417v-12.5c0-2.762 1.098-5.412 3.051-7.365ZM34 4.833a6.25 6.25 0 0 0-6.25 6.25v12.5a6.25 6.25 0 0 0 6.25 6.25h10.417c1.15 0 2.083.933 2.083 2.084v5.387l6.86-6.86c.39-.391.92-.61 1.473-.61h2.084a6.25 6.25 0 0 0 6.25-6.25v-12.5a6.25 6.25 0 0 0-6.25-6.25H34Z"
      />
    </Svg>
  )
}

export default PhoneSMS
