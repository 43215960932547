import { shiftDateFormat } from 'constants/dateFormats'
import { eachDayOfInterval, endOfDay, isWithinInterval, startOfDay } from 'date-fns'
import type { Maybe } from 'shared/graphql/graphql'
import type { Audience, CmsContentT } from 'types'
import { getFormattedFromDate } from './date'

const getEventsInDate = (events: CmsContentT[], date: Date) =>
  events.filter((event) => {
    if (!event.startDate || !event.endDate) return false

    return isWithinInterval(date, { start: startOfDay(event.startDate), end: endOfDay(event.endDate) })
  })

export const getEventsInDateRange = (events: CmsContentT[], range: { start: string; end: string }) => {
  const nextDaysRange = eachDayOfInterval({
    start: range.start,
    end: range.end,
  })

  return Array.from(nextDaysRange).reduce((acc, date) => {
    const eventsInDate = getEventsInDate(events, date)
    const keyDate = getFormattedFromDate(date, shiftDateFormat)

    return acc.set(keyDate, eventsInDate)
  }, new Map<string, CmsContentT[]>())
}

const isAudienceInTenant = (audience: Audience, { ownerId, tenantId }: { ownerId: string; tenantId: string }) => {
  return audience.tenantId === tenantId || audience.ownerId === ownerId
}

export const getEventsFromTenantAndOwner = (
  events: CmsContentT[],
  { tenantId, ownerId }: { ownerId?: Maybe<string>; tenantId?: Maybe<string> },
) => {
  if (!ownerId || !tenantId) return []
  return events.filter((event) =>
    event.audiences?.some((audience) => isAudienceInTenant(audience, { tenantId, ownerId })),
  )
}

export const getEventsFromTenantsAndOwners = (
  events: CmsContentT[],
  tenantOwnerPairs: { tenantId: string; ownerId: string }[],
) => {
  if (!tenantOwnerPairs.length) return []

  return events.filter((event) =>
    event.audiences?.some((audience) =>
      tenantOwnerPairs.some(({ tenantId, ownerId }) => isAudienceInTenant(audience, { tenantId, ownerId })),
    ),
  )
}
