import React from 'react'
import { FilterType, OptionT } from 'components/Filter/types'
import CheckListFilter from '../CheckListFilter/MultipleChoiceFilter'
import DateRangeFilter from '../DateRangeFilter'
import PersonsFilter from '../PersonsFilter/PersonsFilter'

export const getConfigurationComponent = (configuration: OptionT) => {
  switch (configuration.type) {
    case FilterType.MULTIPLE_CHOICE:
      return <CheckListFilter configuration={configuration} />
    case FilterType.PERSONS:
      return <PersonsFilter configuration={configuration} />
    case FilterType.DATE_RANGE:
      return <DateRangeFilter configuration={configuration} />
    default:
      return <></>
  }
}
