import React from 'react'
import { Typography } from '@mui/material'
import DatePickerDrawer from 'redesign/components/organisms/DatePickerDrawer'
import DrawerHeader from 'redesign/components/organisms/DrawerHeader'
import DatePickerInput from '../../atoms/DatePickerInput'
import type { DatePickerProps } from './types'
import { useDatePicker } from './useDatePicker'

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  testId = 'date-picker',
  disabled,
  error,
  onChange,
  value,
  minDate,
  maxDate,
}) => {
  const {
    displayedFieldValue,
    monthOptionProps,
    onChangeValue,
    onClick,
    onClose,
    open,
    yearOptionProps,
  } = useDatePicker({ onChange, value, minDate, maxDate })

  return (
    <>
      <DatePickerInput
        disabled={disabled}
        error={error}
        focused={open}
        label={label}
        onClick={onClick}
        testId={testId}
        value={displayedFieldValue}
      />
      <DatePickerDrawer
        header={
          <DrawerHeader
            // TODO: Add translations
            middleContent={<Typography variant="subtitle1">Select date</Typography>}
            closeIconOnClick={onClose}
          />
        }
        monthOptionProps={monthOptionProps}
        onClose={onClose}
        onChange={onChangeValue}
        open={open}
        yearOptionProps={yearOptionProps}
      />
    </>
  )
}
export default DatePicker
