import { styled, TextField as MuiTextField } from '@mui/material'

export const TextField = styled(MuiTextField)`
  & .MuiOutlinedInput-root {
    :hover {
      ${({ theme, disabled }) => !disabled && `background-color: ${theme.palette.common.hoverCard}`};
    }
  }

  & .MuiInputBase-input {
    ${({ disabled }) => (disabled ? 'pointer-events: none' : 'cursor: pointer')};
  }

  & .MuiOutlinedInput-notchedOutline {
    ${({ disabled }) => (disabled ? 'pointer-events: none' : 'cursor: pointer')};
  }
`
