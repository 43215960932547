import { styled, Typography } from '@mui/material'

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'gap',
})<{ gap?: number }>`
  display: flex;
  gap: ${({ theme, gap }) => theme.spacing(gap ?? 1)};
  align-items: center;
  width: fit-content;
`
export const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'overflowSingleLine',
})<{ overflowSingleLine: boolean }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  justify-content: start;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  line-clamp: ${({ overflowSingleLine }) => (overflowSingleLine ? 1 : 2)};
  -webkit-line-clamp: ${({ overflowSingleLine }) => (overflowSingleLine ? 1 : 2)};
`
