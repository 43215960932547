import { Chip, css, styled } from '@mui/material'
import { TAG_SIZE, TAG_SIZE_VALUES } from './constants'

export const MainContainer = styled(Chip, {
  shouldForwardProp: (prop) =>
    prop !== 'borderColor' &&
    prop !== 'backgroundColor' &&
    prop !== 'tagSize' &&
    prop !== 'noPadding' &&
    prop !== 'fullWidth',
})<{
  backgroundColor?: string
  borderColor?: string
  tagSize: keyof typeof TAG_SIZE
  noPadding?: boolean
  fullWidth?: boolean
}>`
  ${({ noPadding, theme }) =>
    noPadding &&
    `
 .MuiChip-label {
    display: flex;
    padding: 0 ${theme.spacing(0.75)};
  }`}
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 5px;
  border: ${({ theme, borderColor }) => `${theme.spacing(0.125)} solid ${borderColor}`};
  height: ${({ tagSize, theme }) => theme.spacing(TAG_SIZE_VALUES[tagSize])};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`

export const TagContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{
  fullWidth?: boolean
}>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  justify-content: space-between;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`
