import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const ManagerTasks: React.FC<IconProps> = ({
  color = 'currentColor',
  size = ICON_SIZE.medium,
  viewBox = ICON_VIEWBOX.medium,
  isFilled,
  fillOpacity,
}) => {
  const path = isFilled ? (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.091 21h15.817C21.447 21 22 19.797 22 18.994V9.12c0-1.47-1.25-2.006-2.091-2.006h-3.25l-.881-2.653a2.117 2.117 0 0 0-.78-1.06c-.369-.257-.8-.401-1.25-.401h-3.496c-.45 0-.892.134-1.25.401a2.117 2.117 0 0 0-.78 1.06l-.881 2.653H4.09C2.554 7.114 2 8.318 2 9.12v9.874C2 20.465 3.25 21 4.091 21zM15.03 7.114H8.971l.717-2.16a.579.579 0 0 1 .216-.298.613.613 0 0 1 .348-.113h3.496c.123 0 .246.041.348.113a.579.579 0 0 1 .216.298l.717 2.16zm-4.951 9.69c.154.05.307.082.471.082l-.01.02h.092c.195-.01.38-.061.554-.154.154-.082.287-.195.4-.319l4.008-3.857a.769.769 0 0 0 .02-1.09.762.762 0 0 0-1.086-.02l-3.937 3.836-1.055-1.492a.757.757 0 0 0-1.067-.185.762.762 0 0 0-.184 1.07l1.2 1.697c.015.015.03.033.045.051a.92.92 0 0 0 .047.052c.133.133.307.236.502.308z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  ) : (
    <path
      d="M19.8985 21H4.09124C3.25064 21 2 20.4651 2 18.9943V9.12C2 8.31771 2.55356 7.11429 4.09124 7.11429H7.34085L8.22245 4.46057C8.36597 4.02857 8.6325 3.66857 9.00154 3.40114C9.36033 3.13371 9.80113 3 10.2522 3H13.7478C14.1989 3 14.6294 3.144 14.9985 3.40114C15.3675 3.66857 15.634 4.02857 15.7775 4.46057L16.6592 7.11429H19.9088C20.7494 7.11429 22 7.64914 22 9.12V18.9943C22 19.7966 21.4464 21 19.9088 21H19.8985ZM7.89441 8.65714H4.09124C3.93747 8.65714 3.55818 8.65714 3.53767 9.13029V18.9943C3.53767 19.1074 3.53767 19.4469 4.09124 19.4571H19.8985C20.0625 19.4571 20.4316 19.4571 20.4521 18.984V9.12C20.4521 8.82171 20.2676 8.66743 19.8985 8.65714H7.89441ZM8.96053 7.11429H15.0292L14.3116 4.95429C14.2706 4.83086 14.1989 4.728 14.0964 4.656C13.9938 4.584 13.8708 4.54286 13.7478 4.54286H10.2522C10.1292 4.54286 10.0062 4.584 9.90364 4.656C9.80113 4.728 9.72937 4.83086 9.68837 4.95429L8.97078 7.11429H8.96053ZM10.5495 16.8857C10.3854 16.8857 10.2317 16.8549 10.0779 16.8034C9.88314 16.7314 9.70887 16.6286 9.5756 16.4949C9.54485 16.464 9.5141 16.4229 9.48334 16.392L8.28396 14.6949C8.03793 14.3451 8.11994 13.8617 8.46848 13.6251C8.81702 13.3783 9.29882 13.4606 9.5346 13.8103L10.5905 15.3017L14.5269 11.4651C14.8344 11.1669 15.3162 11.1771 15.6135 11.4857C15.9108 11.7943 15.9006 12.2777 15.593 12.576L11.5848 16.4331C11.4721 16.5566 11.3388 16.6697 11.185 16.752C11.0108 16.8446 10.8262 16.896 10.6315 16.9063C10.6007 16.9063 10.57 16.9063 10.5392 16.9063L10.5495 16.8857Z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  )

  return (
    <SvgWrapper viewBox={viewBox} size={size} fill="none">
      {path}
    </SvgWrapper>
  )
}

export default ManagerTasks
