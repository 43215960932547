import { taskStates } from 'pages/Tasks/constants'
import { formatDate } from 'redesign/shared/date'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDateRange } from 'redesign/shared/date/utils'
import routes from 'services/RoutesProvider/routes'
import { ShiftUpdateSeenNotification } from 'shared/graphql/graphql'
import { newDateWithoutTime } from 'utils/date'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getShiftUpdateSeenData = ({ notification, t, locale }: GetNotificationData) => {
  const {
    userNameShort,
    dateFrom: notificationDateFrom,
    dateTo,
    count,
    taskUuid,
  } = notification.data as ShiftUpdateSeenNotification
  const dateFormat = DATE_FORMAT_VARIANTS.longWithoutWeekday
  const dateFrom = newDateWithoutTime(notificationDateFrom)

  const dateRange = formatDateRange({
    dates: {
      startDate: dateFrom,
      endDate: newDateWithoutTime(dateTo),
    },
    dateFormat,
    locale,
    t,
  })

  const startDate = formatDate({
    date: dateFrom,
    dateFormat,
    locale,
    t,
  })
  const textBody =
    count === 1
      ? startDate
      : t(notificationGlosssaryKeys.shiftUpdateSeenMultiday.body, {
          dateRange,
          count: count ?? 0,
        })

  const url = taskUuid
    ? `${routes.shiftUpdateDetail}/${taskUuid}/${taskStates.closed}`
    : `${routes.tasks}/${taskStates.closed}`

  return {
    title: t(notificationGlosssaryKeys.shiftUpdateSeen.title, { frontlinerNameShort: userNameShort }),
    body: textBody,
    route: url,
  }
}
