/* eslint-disable complexity */
import { NotificationTypes } from 'types'
import { GetNotificationData, NotificationData } from '../types'
import { getAbsenceRequestComment } from './getAbsenceRequestComment'
import { getAbsenceRequestDeletionData } from './getAbsenceRequestDeletionData'
import { getCmsNotificationData } from './getCmsNotificationData'
import { getDocumentData } from './getDocumentData'
import { getEmptyNotification } from './getEmptyNotification'
import { getInvitationData } from './getInvitationData'
import { getLinkMessageNotificationData } from './getLinkMessageNotificationData'
import { getMonthlySheetComment } from './getMonthlySheetComment'
import { getMonthlySheetManualReminder } from './getMonthlySheetManualReminder'
import { getMonthlySheetManualReminderForManagerData } from './getMonthlySheetManualReminderForManagerData'
import { getOfficeAbsenceRequestData } from './getOfficeAbsenceRequestData'
import { getOfficeMonthlySheetApprovedData } from './getOfficeMonthlySheetApprovedData'
import { getRecievedUserDataData } from './getRecievedUserDataData'
import { getReminderAbsenceRequestManualManager } from './getRemidnerAbsenceRequestManualManager'
import { getReminderData } from './getReminderData'
import { getReminderShareUserData } from './getReminderShareUserData'
import { getReminderShiftUpdateData } from './getReminderShiftUpdateData'
import { getResignationInfoData } from './getResignationInfoData'
import { getResolveAbsenceRequestDeletionData } from './getResolveAbsenceRequestDeletionData'
import { getResolvedAbsenceRequestData } from './getResolvedAbsenceRequestData'
import { getSecureConnectionComment } from './getSecureConnectionComment'
import { getSecureConnectionCompletedData } from './getSecureConnectionCompletedData'
import { getSecureConnectionData } from './getSecureConnectionData'
import { getSecureConnectionManualReminderData } from './getSecureConnectionManualReminderData'
import { getSecureConnectionReminderData } from './getSecureConnectionReminderData'
import { getShareUserData } from './getShareUserData'
import { getShareUserDataAcceptedData } from './getShareUserDataAcceptedData'
import { getShareUserDataComment } from './getShareUserDataComment'
import { getShareUserDataManualReminder } from './getShareUserDataManualReminder'
import { getShiftData } from './getShiftData'
import { getShiftUpdateManualReminder } from './getShiftUpdateManualReminder'
import { getShiftUpdateSeenData } from './getShiftUpdateSeenData'

export const normalizeNotificationData = ({ notification, t, locale }: GetNotificationData): NotificationData => {
  switch (notification.type) {
    case NotificationTypes.absenceRequestCommentForFrontliner:
      return getAbsenceRequestComment({
        notification,
        t,
        isManager: false,
      })
    case NotificationTypes.document:
      return getDocumentData({
        notification,
        t,
      })
    case NotificationTypes.invitation:
      return getInvitationData({ notification, t })

    case NotificationTypes.linkMessage:
      return getLinkMessageNotificationData({ notification, t })

    case NotificationTypes.officeAbsenceRequest:
      return getOfficeAbsenceRequestData({ notification, t })

    case NotificationTypes.officeMonthlySheetApproved:
      return getOfficeMonthlySheetApprovedData({ notification, t })

    case NotificationTypes.receivedUserData:
      return getRecievedUserDataData({ notification, t })

    case NotificationTypes.reminderShiftUpdate:
      return getReminderShiftUpdateData({ notification, t })

    case NotificationTypes.reminder:
      return getReminderData({ notification, t })

    case NotificationTypes.resolvedAbsenceRequest:
      return getResolvedAbsenceRequestData({ notification, t, locale })

    case NotificationTypes.shareUserData:
      return getShareUserData({ notification, t })

    case NotificationTypes.shareUserDataCommentForFrontliner:
      return getShareUserDataComment({ notification, t, isManager: false })

    case NotificationTypes.shareUserDataAccepted:
      return getShareUserDataAcceptedData({ notification, t })

    case NotificationTypes.shift:
      return getShiftData({ notification, t })

    case NotificationTypes.shiftUpdateSeen:
      return getShiftUpdateSeenData({ notification, t })

    case NotificationTypes.reminderShiftUpdateManual:
      return getShiftUpdateManualReminder({ notification, t })

    case NotificationTypes.reminderMonthlySheetManual:
      return getMonthlySheetManualReminder({ notification, t })

    case NotificationTypes.reminderMonthlySheetManualForManager:
      return getMonthlySheetManualReminderForManagerData({ notification, t })

    case NotificationTypes.reminderAbsenceRequestManualForManager:
      return getReminderAbsenceRequestManualManager({ notification, t })

    case NotificationTypes.reminderShareUserData:
      return getReminderShareUserData({ notification, t })

    case NotificationTypes.reminderShareUserDataManual:
      return getShareUserDataManualReminder({ notification, t, locale, isManager: false })

    case NotificationTypes.reminderShareUserDataManualForManager:
      return getShareUserDataManualReminder({ notification, t, isManager: true })

    case NotificationTypes.monthlySheetCommentForFrontliner:
      return getMonthlySheetComment({ notification, t, isManager: false })

    case NotificationTypes.monthlySheetCommentForManager:
      return getMonthlySheetComment({ notification, t, isManager: true })

    case NotificationTypes.absenceRequestCommentForManager:
      return getAbsenceRequestComment({ notification, t, isManager: true })

    case NotificationTypes.absenceRequestRequestDeletion:
      return getAbsenceRequestDeletionData({ notification, t, locale })

    case NotificationTypes.resolvedAbsenceRequestRequestDeletion:
      return getResolveAbsenceRequestDeletionData({ notification, t, locale })

    case NotificationTypes.shareUserDataCommentForManager:
      return getShareUserDataComment({ notification, t, isManager: true })

    case NotificationTypes.resignationInfo:
      return getResignationInfoData({ notification, t })

    case NotificationTypes.cmsNewContent:
      return getCmsNotificationData({ notification, t, locale })

    case NotificationTypes.cmsUpdateContent:
      return getCmsNotificationData({ notification, t, locale })

    case NotificationTypes.secureConnection:
      return getSecureConnectionData({ notification, t })

    case NotificationTypes.reminderSecureConnection:
      return getSecureConnectionReminderData({ notification, t })

    case NotificationTypes.secureConnectionCommentForFrontliner:
      return getSecureConnectionComment({ notification, t, isManager: false })

    case NotificationTypes.secureConnectionCommentForManager:
      return getSecureConnectionComment({ notification, t, isManager: true })

    case NotificationTypes.reminderSecureConnectionManualForFrontliner:
      return getSecureConnectionManualReminderData({ notification, t })

    case NotificationTypes.secureConnectionCompleted:
      return getSecureConnectionCompletedData({ notification, t })

    default:
      return getEmptyNotification({ notification, t })
  }
}
