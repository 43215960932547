import { AbsenceFullDay, AbsenceHalfDayEvening, AbsenceHalfDayMorning } from '../../Icon'

export const ABSENCE_TYPE = {
  fullDay: 'fullDay',
  halfDayEvening: 'halfDayEvening',
  halfDayMorning: 'halfDayMorning',
} as const

export const ABSENCE_ICON = {
  [ABSENCE_TYPE.fullDay]: AbsenceFullDay,
  [ABSENCE_TYPE.halfDayEvening]: AbsenceHalfDayEvening,
  [ABSENCE_TYPE.halfDayMorning]: AbsenceHalfDayMorning,
}
