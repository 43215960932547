import React from 'react'
import { Typography } from '@mui/material'
import DotAlert from 'redesign/components/atoms/DotAlert'
import ContentFooter from 'redesign/components/molecules/ContentFooter'
import HeaderBlock from 'redesign/components/molecules/HeaderBlock'
import Card from 'redesign/components/organisms/Card'
import { Body, Header } from './styles'
import { ContentCardProps } from './types'

const ContentCard: React.FC<ContentCardProps> = ({
  header,
  isNew,
  url,
  download,
  dataTestId,
  onClick,
  bodyText,
  footerProps,
}) => {
  const { icon, component, title } = header

  return (
    <Card isNew={isNew} url={url} download={download} onClick={onClick} dataTestId={dataTestId}>
      <Header>
        <DotAlert isNew={isNew}>
          <HeaderBlock icon={icon} component={component} title={title} isLink={Boolean(url)} />
        </DotAlert>
      </Header>
      <Body>
        {bodyText && <Typography variant="body1">{bodyText}</Typography>}
        <ContentFooter {...footerProps} />
      </Body>
    </Card>
  )
}

export default ContentCard
