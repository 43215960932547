import React, { useContext } from 'react'
import { TabContext } from '@mui/lab'
import NotificationsHeader from 'redesign/components/organisms/Headers/NotificationsHeader'
import Page from 'redesign/components/templates/Page'
import SubheaderTabContext from 'redesign/services/SubheaderTabs/context'
import NotificationsList from '../ContentLists/Notifications/NotificationsList'
import { useNotificationsList } from '../ContentLists/Notifications/useNotificationsList'
import { Panel } from './styles'

const NotificationsContent: React.FC = () => {
  const { selectedTabName, pages } = useContext(SubheaderTabContext)
  const {
    notifications,
    loading,
    loadMore,
    handleAcknowledgeAll,
    showLoadMoreButton,
    dismiss,
    hasManagerView,
    showAcknowledgeAll,
  } = useNotificationsList()

  return (
    <TabContext value={selectedTabName}>
      <Page header={<NotificationsHeader hasManagerView={hasManagerView} />} paddingX={0}>
        {pages?.map(({ name }) => (
          <Panel key={name} value={name}>
            <NotificationsList
              notifications={notifications}
              isLoading={loading}
              showLoadMoreButton={showLoadMoreButton}
              loadMore={loadMore}
              dismiss={dismiss}
              handleAcknowledgeAll={handleAcknowledgeAll}
              showAcknowledgeAll={showAcknowledgeAll}
            />
          </Panel>
        ))}
      </Page>
    </TabContext>
  )
}

export default NotificationsContent
