import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'

declare module '@mui/material/Badge' {
  interface BadgePropsVariantOverrides {
    small: true
  }
}

const variants: ComponentsVariants['MuiBadge'] = [
  {
    props: {
      variant: 'small',
    },
    style: {
      '.MuiBadge-small': {
        height: '1rem',
        padding: '0 0.25rem',
        minWidth: '1rem',
      },
    },
  },
]

const styleOverrides: ComponentsOverrides<Theme>['MuiBadge'] = {
  root: () => ({
    '.MuiBadge-badge': {
      fontSize: '0.75rem',
      fontWeight: 400,
      borderRadius: '0.625rem',
    },
    '.MuiBadge-standard': {
      height: '1.25rem',
      padding: '0 0.375rem',
      minWidth: '1.25rem',
    },
  }),
}

const MuiBadge = {
  styleOverrides,
  variants,
}

export default MuiBadge
