import React from 'react'
import { Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import AlertTag from 'redesign/components/atoms/Tag/AlertTag'
import EventTag from 'redesign/components/atoms/Tag/EventTag'
import ShiftTag from 'redesign/components/atoms/Tag/ShiftTag'
import { TAG_SIZE } from 'redesign/components/atoms/Tag/constants'
import { getShiftKey } from 'types/utils'
import { ShiftContainer } from './styles'
import type { TShifts } from './types'
import { useShiftTagList } from './useShiftTagList'

const ShiftTags: React.FC<TShifts> = ({ tenants, events, shiftUpdates }) => {
  const { t } = useFavurTranslation()
  const { hasUnacknowledgedTask, showFootnote, showingShifts, filteredEvents } = useShiftTagList({
    tenants,
    shiftUpdates,
    events,
  })

  return (
    <>
      <ShiftContainer>
        {hasUnacknowledgedTask ? (
          <AlertTag tagSize={TAG_SIZE.small} text={t('page.shifts.shiftCard.shiftUpdate.hidden')} />
        ) : (
          showingShifts.map((shift) => <ShiftTag shift={shift} key={getShiftKey(shift, 'nextShift')} />)
        )}
        {filteredEvents.length ? <EventTag numberOfEvents={filteredEvents.length} tagSize={TAG_SIZE.small} /> : null}
      </ShiftContainer>
      {showFootnote && <Typography variant="caption">{`${t('page.dashboard.content.manualShift')}`}</Typography>}
    </>
  )
}

export default ShiftTags
