import { styled } from '@mui/material'

export const TaskContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`
