import { SwipeableDrawer as MuiSwipeableDrawer, styled } from '@mui/material'

export const SwipeableDrawer = styled(MuiSwipeableDrawer, {
  shouldForwardProp: (prop) => prop !== 'fullHeight' && prop !== 'heightSpacing',
})<{ fullHeight: boolean; heightSpacing?: number }>`
  & .MuiPaper-root {
    border-top-right-radius: ${({ theme }) => theme.spacing(3)};
    border-top-left-radius: ${({ theme }) => theme.spacing(3)};
    ${({ fullHeight }) => fullHeight && `height: calc(100% -  env(safe-area-inset-top))`};
    ${({ theme, heightSpacing }) =>
      heightSpacing && `height: calc(${theme.spacing(heightSpacing)} -  env(safe-area-inset-top))`};
    max-width: ${({ theme }) => theme.spacing(75)};
    margin: 0 auto;
    z-index: 30;
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding-bottom: env(safe-area-inset-bottom);
  }
`

export const ContentContainer = styled('div')`
  height: 100%;
  flex: 1;
  overflow: hidden;
`

export const FooterContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: ${({ theme }) => theme.spacing(2)};
  margin-top: auto;
  bottom: 0;
  background: ${({ theme }) => theme.palette.background.default};
  border-top: ${({ theme }) => `${theme.spacing(0.0625)} solid ${theme.palette.common?.cardBorder}`};
`
