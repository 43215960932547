import { styled } from '@mui/material'

export const Container = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
`

export const Puller = styled('div')`
  width: ${({ theme }) => theme.spacing(4)};
  height: ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.spacing(0.25)};
  background-color: ${({ theme }) => theme.palette.common.cardBorder};
`
