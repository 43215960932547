import React, { useEffect, useMemo, useState } from 'react'
import { Badge } from '@mui/material'
import { Page } from 'redesign/components/organisms/Tabs/types'
import { PAGE_KEYS } from 'redesign/pages/Notifications/constants'
import { NotificationCounts } from 'shared/graphql/graphql'
import { SubheaderTabContextProviderProps } from '../../components/organisms/Headers/NotificationsHeader/types'
import TabHeaderContext from './context'

const SubheaderTabProvider: React.FC<SubheaderTabContextProviderProps> = ({ children, pages: originalPages }) => {
  const [pages, setPages] = useState<Page[]>(originalPages)
  const [selectedTabName, setSelectedTabName] = useState<string>(pages[0]?.name ?? '')
  const [counts, setCounts] = useState<NotificationCounts>({ manager: 0, personal: 0 })
  const providerValue = useMemo(
    () => ({
      setSelectedTabName,
      selectedTabName,
      pages,
      counts,
      setCounts,
    }),
    [counts, pages, selectedTabName],
  )

  useEffect(() => {
    const pagesWithIcons = pages.map((page) => {
      const pageCount = page.name === PAGE_KEYS.supervisor ? counts.manager : counts.personal

      return {
        ...page,
        icon: <Badge badgeContent={pageCount} color="primary" />,
      }
    })

    setPages(pagesWithIcons)
    // Disabled to avoid errors regarding excesive rerenders from pages self triggering the use effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counts])

  return <TabHeaderContext.Provider value={providerValue}>{children}</TabHeaderContext.Provider>
}

export default SubheaderTabProvider
