import { ROLE_VIEWS } from 'constants/roles'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDateRange } from 'redesign/shared/date/utils'
import routes from 'services/RoutesProvider/routes'
import { OfficeMonthlySheetApprovedNotification } from 'shared/graphql/graphql'
import { newDateWithoutTime } from 'utils/date'
import { getShortNameFromName } from 'utils/person'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getOfficeMonthlySheetApprovedData = ({ notification, t, locale }: GetNotificationData) => {
  const {
    userNameShort,
    userName,
    taskUuid,
    dateFrom,
    dateTo,
  } = notification.data as OfficeMonthlySheetApprovedNotification
  const { title } = notificationGlosssaryKeys.officeMonthlySheetApproved
  const dateFormat = DATE_FORMAT_VARIANTS.shortWithWeekday

  const body = formatDateRange({
    dates: {
      startDate: newDateWithoutTime(dateFrom),
      endDate: newDateWithoutTime(dateTo),
    },
    dateFormat,
    locale,
    t,
  })

  return {
    title: t(title, {
      frontlinerNameShort: userNameShort ?? getShortNameFromName(userName ?? ''),
    }),
    body,
    route: `${routes.monthlySheets}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
