import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const SettingsIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
  isFilled,
}) =>
  isFilled ? (
    <SvgWrapper viewBox={viewBox} size={size}>
      <path d="M12 9c-1.7 0-3 1.3-3 3s1.4 3 3 3 3-1.3 3-3-1.3-3-3-3Z" fill={color} />
      <path
        d="M20.3 12.6c-.2-.2-.3-.4-.3-.7 0-.3 0-.5.3-.7l.9-.8c.7-.7 1-1.7.6-2.6-.4-.9-1.3-1.5-2.3-1.4h-1.2c-.2 0-.5 0-.7-.2-.2-.2-.3-.4-.3-.7V4.3c.1-1-.5-1.9-1.4-2.3-.9-.4-2-.1-2.6.6l-.8.9c-.2.2-.4.3-.7.3-.3 0-.5 0-.7-.3l-.8-.9c-.7-.7-1.7-1-2.6-.6-.9.4-1.5 1.3-1.4 2.3v1.2c0 .2 0 .5-.3.7-.3.2-.4.3-.7.3H4.1c-1 0-1.9.5-2.3 1.4-.4.9 0 2 .6 2.6l.9.8c.2.2.3.4.3.7 0 .3 0 .5-.3.7l-.9.8c-.7.7-1 1.7-.6 2.6.4.9 1.3 1.5 2.3 1.4h1.2c.2 0 .5 0 .7.2.2.2.3.4.3.7v1.2c0 1 .5 1.9 1.4 2.3.9.4 2 0 2.6-.6l.8-.9c.2-.2.4-.3.7-.3.3 0 .5.1.7.3l.8.9c.7.7 1.7 1 2.6.6.9-.4 1.5-1.3 1.4-2.3v-1.2c0-.2 0-.5.2-.7.2-.2.4-.3.7-.3h1.2c1 0 1.9-.5 2.3-1.4.4-.9 0-2-.6-2.6l-.8-.8ZM12 16.4c-2.4 0-4.4-2-4.4-4.4 0-2.4 1.9-4.4 4.4-4.4 2.5 0 4.4 2 4.4 4.4 0 2.4-2 4.4-4.4 4.4Z"
        fill={color}
      />
    </SvgWrapper>
  ) : (
    <SvgWrapper viewBox={viewBox} size={size}>
      <path
        d="M20.3 12.6c-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7l.9-.8c.7-.7 1-1.7.6-2.6-.4-.9-1.3-1.5-2.3-1.4h-1.2c-.2 0-.5 0-.7-.2-.2-.2-.3-.4-.3-.7V4.3c.1-1-.5-1.9-1.4-2.3-.9-.4-2-.1-2.6.6l-.8.9c-.2.2-.4.3-.7.3-.3 0-.5 0-.7-.3l-.8-.9c-.7-.7-1.7-1-2.6-.6-.9.4-1.5 1.3-1.4 2.3v1.2c0 .2 0 .5-.3.7-.3.2-.4.3-.7.3H4.1c-1 0-1.9.5-2.3 1.4-.4.9-.1 2 .6 2.6l.9.8c.2.2.3.4.3.7 0 .3 0 .5-.3.7l-.9.8c-.7.7-1 1.7-.6 2.6.4.9 1.3 1.5 2.3 1.4h1.2c.2 0 .5 0 .7.2.2.2.3.4.3.7v1.2c0 1 .5 1.9 1.4 2.3.9.4 2 .1 2.6-.6l.8-.9c.2-.2.4-.3.7-.3.3 0 .5.1.7.3l.8.9c.7.7 1.7 1 2.6.6.9-.4 1.5-1.3 1.4-2.3v-1.2c0-.2 0-.5.2-.7.2-.2.4-.3.7-.3h1.2c1 .1 1.9-.5 2.3-1.4.4-.9 0-2-.6-2.6m-.6 2.1c-.1.3-.5.6-.9.5h-1.2c-.7 0-1.3.2-1.8.6-.5.5-.7 1.1-.7 1.8v1.1c0 .4-.2.7-.5.9-.3.1-.7 0-1-.2l-.8-.9c-.4-.5-1.1-.8-1.7-.8-.6 0-1.3.3-1.7.8l-.8.9c-.3.3-.6.4-1 .2-.3-.1-.6-.5-.5-.9v-1.1c0-.7-.2-1.3-.6-1.8-.5-.5-1.1-.7-1.8-.7H4.3c-.4 0-.7-.2-.9-.5-.1-.3 0-.7.2-1l.9-.8c.5-.4.8-1.1.8-1.7 0-.6-.3-1.3-.8-1.7l-.9-.8c-.3-.3-.4-.6-.2-1 0-.3.5-.6.9-.5h1.2c.7 0 1.3-.2 1.8-.6.5-.5.7-1.1.7-1.8V4.3c0-.4.1-.7.5-.9.3-.1.7 0 1 .2l.8.9c.4.5 1.1.8 1.7.8.6 0 1.3-.3 1.7-.8l.8-.9c.3-.3.6-.4 1-.2.3 0 .6.5.5.9v1.2c0 .7.2 1.3.6 1.8.5.5 1.1.7 1.8.7h1.2c.4 0 .7 0 .9.5.1.3 0 .7-.2 1l-.9.8c-.5.4-.8 1.1-.8 1.7 0 .6.3 1.3.8 1.7l.9.8c.3.3.4.6.2 1Z"
        fill={color}
      />
      <path
        d="M12 7.6c-2.5 0-4.4 2-4.4 4.4 0 2.4 2 4.4 4.4 4.4 2.4 0 4.4-2 4.4-4.4 0-2.4-2-4.4-4.4-4.4Zm0 7.4c-1.6 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3Z"
        fill={color}
      />
    </SvgWrapper>
  )

export default SettingsIcon
