import React, { useMemo, useState } from 'react'
import { ROLE_VIEWS } from 'constants/roles'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useRolesViews from 'hooks/useRolesViews'
import useDismissTaskNotifications from 'pages/Tasks/hooks/useDismissTaskNotifications'
import { taskQueryApollo } from 'pages/Tasks/queries'
import { ShareUserDataTaskT, TaskT } from 'pages/Tasks/types'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { ShareUserDataFormContext } from '.'
import DetailPage from './DetailPage'
import ShareUserDataForm from './Form'

const UserDataDetailsContent: React.FC<{ favurUuid: string }> = ({ favurUuid }) => {
  const history = useHistory()
  const [showForm, setShowForm] = useState(false)
  const { activeView } = useRolesViews()
  const isOffice = useMemo(() => activeView === ROLE_VIEWS.office, [activeView])
  const [task, setTask] = useState<TaskT<ShareUserDataTaskT>>()
  const { loading: dismissLoading } = useDismissTaskNotifications({ taskUuid: favurUuid, isManager: isOffice })

  const { loading: taskLoading, refetch } = useJamesApolloQuery<{ task: TaskT<ShareUserDataTaskT> }>(taskQueryApollo, {
    fetchPolicy: 'no-cache',
    variables: { favurUuid, office: isOffice },
    onCompleted: (data) => {
      const { task: queriedTask } = data
      setTask(queriedTask)
    },
    onError() {
      history.push(routes.dashboard)
    },
  })

  const loading = taskLoading || dismissLoading

  return (
    <ShareUserDataFormContext.Provider value={{ setShowForm, refetch, favurUuid }}>
      {showForm ? (
        <ShareUserDataForm onClose={() => setShowForm(false)} task={task} />
      ) : (
        <DetailPage task={task} loading={loading} />
      )}
    </ShareUserDataFormContext.Provider>
  )
}

export default UserDataDetailsContent
