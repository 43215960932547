import React from 'react'
import { Typography } from '@mui/material'
import LoadMoreButton from 'redesign/components/atoms/Buttons/LoadMoreButton'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import { EmptyContainer, ListContainer } from './styles'
import type { ListWrapperProps } from './types'

const ListWrapper: React.FC<ListWrapperProps> = ({
  hasContent,
  message,
  isLoading,
  children,
  showLoadMoreButton,
  loadMore,
  buttonTestId,
}) => {
  if (!hasContent) {
    return (
      <EmptyContainer>
        {isLoading && <LoadingSpinner />}
        {/* Placeholder until design */}
        {!isLoading && <Typography variant="h3">{message}</Typography>}
      </EmptyContainer>
    )
  }
  return (
    <div>
      <ListContainer>{children}</ListContainer>

      {showLoadMoreButton && loadMore && (
        <LoadMoreButton onClick={loadMore} testId={buttonTestId} loading={Boolean(isLoading)} />
      )}
    </div>
  )
}

export default ListWrapper
