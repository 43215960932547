import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const DocumentCenterIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
  isFilled,
}) => {
  const path = isFilled ? (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.674 2.765A1.507 1.507 0 0 1 4.8 2.25h14.4c.442 0 .844.198 1.126.515.28.315.424.724.424 1.135v16.2c0 .411-.144.82-.424 1.135a1.507 1.507 0 0 1-1.126.515H4.8c-.441 0-.844-.198-1.126-.515A1.71 1.71 0 0 1 3.25 20.1V3.9c0-.41.144-.82.424-1.135zM6.25 8A.75.75 0 0 1 7 7.25h10a.75.75 0 0 1 0 1.5H7A.75.75 0 0 1 6.25 8zm0 4a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75zm0 4a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75z"
      fill={color}
      fillOpacity=".87"
    />
  ) : (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.674 2.765A1.507 1.507 0 0 1 4.8 2.25h14.4c.442 0 .844.198 1.126.515.28.315.424.724.424 1.135v16.2c0 .411-.144.82-.424 1.135a1.507 1.507 0 0 1-1.126.515H4.8c-.441 0-.844-.198-1.126-.515A1.71 1.71 0 0 1 3.25 20.1V3.9c0-.41.144-.82.424-1.135zm1.134.985a.073.073 0 0 0-.013.012.213.213 0 0 0-.045.138v16.2c0 .066.024.115.045.138.006.007.01.01.013.012h14.384a.069.069 0 0 0 .013-.012.213.213 0 0 0 .045-.138V3.9a.213.213 0 0 0-.045-.138.073.073 0 0 0-.013-.012H4.808zM6.25 8A.75.75 0 0 1 7 7.25h10a.75.75 0 0 1 0 1.5H7A.75.75 0 0 1 6.25 8zm0 4a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75zm0 4a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75z"
      fill={color}
      fillOpacity=".87"
    />
  )

  return (
    <SvgWrapper viewBox={viewBox} size={size}>
      {path}
    </SvgWrapper>
  )
}

export default DocumentCenterIcon
