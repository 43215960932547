import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

export const Button = styled(LoadingButton)`
  width: fit-content;
`

export const Container = styled('div')`
  display: flex;
  justify-content: center;
`
