export const BUTTON_GROUP_MOTION = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { staggerChildren: 0.02 },
  },
}

export const BUTTON_ITEM_MOTION = (index: number) => ({
  hidden: { opacity: 0, x: -20, scale: 0.95 },
  visible: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: { duration: Math.max(0.2 - 0.05 * index, 0.03), ease: 'easeOut' },
  },
})
