import React from 'react'
import WheelPickerColumn from 'redesign/components/atoms/WheelPickerColumn'
import { IonPicker } from './styles'
import type { WheelPickerProps } from './types'

const WheelPicker: React.FC<WheelPickerProps> = ({ firstOptions, secondOptions, thirdOptions }) => (
  <IonPicker data-testid="wheel-picker">
    {firstOptions?.options?.length && <WheelPickerColumn {...firstOptions} />}
    {secondOptions?.options?.length && <WheelPickerColumn {...secondOptions} />}
    {thirdOptions?.options?.length && <WheelPickerColumn {...thirdOptions} />}
  </IonPicker>
)

export default WheelPicker
