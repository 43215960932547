export const TAG_SIZE = {
  large: 'large',
  small: 'small',
} as const

export const TAG_SIZE_VALUES = {
  [TAG_SIZE.large]: 3,
  [TAG_SIZE.small]: 2.25,
} as const

export const TAG_TYPOGRAPHY_VARIANT = {
  [TAG_SIZE.large]: 'chipBig',
  [TAG_SIZE.small]: 'chipSmall',
}
