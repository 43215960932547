import { styled } from '@mui/material'

export const Body = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(0, 2, 2, 2)};
  gap: ${({ theme }) => theme.spacing(1)};
`

export const Header = styled('div')`
  padding: ${({ theme }) => theme.spacing(1, 1, 0, 1)}; // Note: right padding is less to accomodate the alert dot
`
