import React from 'react'
import CardWidget from 'redesign/components/atoms/CardWidget'
import IconAndTextBlock from 'redesign/components/molecules/IconAndTextBlock'
import { QuickLinkListContainer, QuickListItemContainer } from './styles'
import { QuickLinkListProps } from './types'

const QuickLinkList: React.FC<QuickLinkListProps> = ({ list = [], defaultIcon }) => (
  <QuickLinkListContainer>
    {list.map(({ title, onClick, icon, testId }) => (
      <QuickListItemContainer key={title}>
        <CardWidget onClick={onClick} testId={testId}>
          <IconAndTextBlock overflowSingleLine icon={icon ?? defaultIcon} title={title} />
        </CardWidget>
      </QuickListItemContainer>
    ))}
  </QuickLinkListContainer>
)

export default QuickLinkList
