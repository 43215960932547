import React from 'react'
import { Button } from '@mui/material'
import WheelPicker from 'redesign/components/molecules/WheelPicker'
import Drawer from '../Drawer'
import { ButtonContainer, Container } from './styles'
import type { DatePickerDrawerProps } from './types'

// TODO: Add translations
const DatePickerDrawer: React.FC<DatePickerDrawerProps> = ({
  onChange,
  onClose,
  header,
  monthOptionProps,
  open,
  yearOptionProps,
}) => (
  <Drawer testId="date-picker-drawer" onClose={onClose} showPuller open={open} header={header}>
    <Container>
      <WheelPicker firstOptions={monthOptionProps} secondOptions={yearOptionProps} />
      <ButtonContainer>
        <Button fullWidth variant="contained" onClick={onChange}>
          Apply filters
        </Button>
      </ButtonContainer>
    </Container>
  </Drawer>
)

export default DatePickerDrawer
