import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const CalendarNoDataIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
}) => (
  <SvgWrapper viewBox={viewBox} size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.991 2c.408 0 .738.33.738.736v.514h4.781v-.514a.737.737 0 0 1 1.475 0v.514h1.765a1.99 1.99 0 0 1 1.99 1.986v3.54a.737.737 0 0 1-1.476 0v-.304H3.974v6.761a.513.513 0 0 0 .515.514h5.63a.737.737 0 1 1 0 1.473H4.49A1.99 1.99 0 0 1 2.5 15.232V5.236A1.985 1.985 0 0 1 4.489 3.25h1.765v-.514c0-.406.33-.736.737-.736Zm-.737 2.723H4.489a.514.514 0 0 0-.514.513v1.763h12.29V5.236a.513.513 0 0 0-.515-.513h-1.765v.513a.737.737 0 0 1-1.475 0v-.513H7.73v.513a.737.737 0 0 1-1.475 0v-.513Zm10.583 7.458a.716.716 0 0 0-.64.395h-.001l-3.45 6.913v.003a.723.723 0 0 0 .025.697.735.735 0 0 0 .61.338h6.935a.73.73 0 0 0 .61-.337.721.721 0 0 0 .02-.694l-.003-.006-3.465-6.913a.721.721 0 0 0-.641-.396Zm-1.151-1.146a2.192 2.192 0 0 1 3.11.88l3.463 6.91.002.003a2.194 2.194 0 0 1-.075 2.128l-.008.012A2.208 2.208 0 0 1 20.324 22h-6.951a2.21 2.21 0 0 1-1.853-1.032l-.006-.009a2.195 2.195 0 0 1-.086-2.128v-.001l3.449-6.912v-.002c.183-.363.463-.668.809-.882Zm1.168 2.741c.407 0 .737.33.737.737v1.841a.737.737 0 0 1-1.475 0v-1.841c0-.407.33-.737.738-.737Zm-.793 3.993a1.044 1.044 0 1 1 1.478 1.476 1.044 1.044 0 0 1-1.478-1.476Z"
      fill={color}
      fillOpacity=".6"
    />
  </SvgWrapper>
)

export default CalendarNoDataIcon
