import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const AbsencePlanIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
  isFilled,
  fillOpacity,
}) => {
  const path = isFilled ? (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m19.087 20.066.037-.028a10.426 10.426 0 0 0 .426-.344.639.639 0 0 0 .153-.79.667.667 0 0 0-.749-.328 7.203 7.203 0 0 1-.471.1l-.03.005a7.214 7.214 0 0 1-2.678-.047 7.133 7.133 0 0 1-2.884-1.313 6.944 6.944 0 0 1-2.003-2.418 6.792 6.792 0 0 1 0-6.066 6.945 6.945 0 0 1 2.003-2.418 7.133 7.133 0 0 1 2.883-1.313 7.214 7.214 0 0 1 2.679-.046l.03.005a7.177 7.177 0 0 1 .471.1.667.667 0 0 0 .748-.329.638.638 0 0 0-.151-.788 10.585 10.585 0 0 0-.425-.345l-.037-.028A10.7 10.7 0 0 0 12.595 1.5a10.78 10.78 0 0 0-4.913 1.182A10.527 10.527 0 0 0 3.89 5.96a10.24 10.24 0 0 0-1.807 4.625c-.212 1.663-.01 3.35.59 4.92a10.365 10.365 0 0 0 2.853 4.089 10.67 10.67 0 0 0 4.466 2.328c1.659.412 3.395.425 5.06.036a10.693 10.693 0 0 0 4.036-1.892z"
        fill={color}
        fillOpacity={fillOpacity}
      />
      <path
        d="M13.211 10.647h1.46v1.459c0 .399.33.73.729.73.398 0 .73-.331.73-.73v-1.459h1.458c.399 0 .73-.33.73-.73 0-.398-.331-.729-.73-.729h-1.459V7.73c0-.398-.33-.729-.73-.729-.398 0-.729.33-.729.73v1.458h-1.459c-.398 0-.73.33-.73.73 0 .399.332.73.73.73zM18.074 14.294c0 .399.33.73.73.73h.729v.73c0 .398.33.729.73.729.398 0 .729-.331.729-.73v-.73h.73c.398 0 .729-.33.729-.729 0-.399-.33-.73-.73-.73h-.73v-.729c0-.398-.33-.729-.729-.729-.398 0-.729.33-.729.73v.729h-.73c-.398 0-.729.33-.729.73z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </>
  ) : (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m19.087 20.066.037-.028a10.426 10.426 0 0 0 .426-.344.639.639 0 0 0 .153-.79.667.667 0 0 0-.749-.328 7.203 7.203 0 0 1-.471.1l-.03.005a7.214 7.214 0 0 1-2.678-.047 7.133 7.133 0 0 1-2.884-1.313 6.944 6.944 0 0 1-2.003-2.418 6.792 6.792 0 0 1 0-6.066 6.945 6.945 0 0 1 2.003-2.418 7.133 7.133 0 0 1 2.883-1.313 7.214 7.214 0 0 1 2.679-.046l.03.005a7.177 7.177 0 0 1 .471.1.667.667 0 0 0 .748-.329.638.638 0 0 0-.151-.788 10.585 10.585 0 0 0-.425-.345l-.037-.028A10.7 10.7 0 0 0 12.595 1.5a10.78 10.78 0 0 0-4.913 1.182A10.527 10.527 0 0 0 3.89 5.96a10.24 10.24 0 0 0-1.807 4.625c-.212 1.663-.01 3.35.59 4.92a10.365 10.365 0 0 0 2.853 4.089 10.67 10.67 0 0 0 4.466 2.328c1.659.412 3.395.425 5.06.036a10.693 10.693 0 0 0 4.036-1.892zM12.595 2.98h.003a9.27 9.27 0 0 1 3.293.599 8.616 8.616 0 0 0-3.9 1.662 8.427 8.427 0 0 0-2.429 2.934 8.274 8.274 0 0 0 0 7.389 8.427 8.427 0 0 0 2.43 2.934 8.615 8.615 0 0 0 3.893 1.66 9.337 9.337 0 0 1-5.537.325 9.19 9.19 0 0 1-3.846-2.004 8.885 8.885 0 0 1-2.446-3.505 8.684 8.684 0 0 1-.505-4.202 8.759 8.759 0 0 1 1.547-3.957 9.045 9.045 0 0 1 3.26-2.815 9.299 9.299 0 0 1 4.237-1.02z"
        fill={color}
        fillOpacity={fillOpacity}
      />
      <path
        d="M13.211 10.647h1.46v1.459c0 .399.33.73.729.73.398 0 .73-.331.73-.73v-1.459h1.458c.399 0 .73-.33.73-.73 0-.398-.331-.729-.73-.729h-1.459V7.73c0-.398-.33-.729-.73-.729-.398 0-.729.33-.729.73v1.458h-1.459c-.398 0-.73.33-.73.73 0 .399.332.73.73.73zM18.074 14.294c0 .399.33.73.73.73h.729v.73c0 .398.33.729.73.729.398 0 .729-.331.729-.73v-.73h.73c.398 0 .729-.33.729-.729 0-.399-.33-.73-.73-.73h-.73v-.729c0-.398-.33-.729-.729-.729-.398 0-.729.33-.729.73v.729h-.73c-.398 0-.729.33-.729.73z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </>
  )

  return (
    <SvgWrapper viewBox={viewBox} size={size}>
      {path}
    </SvgWrapper>
  )
}

export default AbsencePlanIcon
