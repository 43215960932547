import { useCallback, useEffect, useState } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { taskStates } from 'pages/Tasks/constants'
import { allManagedPersonsQuery, tasksPaginatedQuery } from 'pages/Tasks/queries'
import { ShareUserDataTaskT, TasksPaginated, TaskT } from 'pages/Tasks/types'
import { taskTypes } from 'shared/constants'
import { PersonT, teamPermissionTypes, TenantT } from 'types'

type ShareUserDataTask = TaskT<ShareUserDataTaskT>
export type PersonWithWorkflow = PersonT & { shareUserDataTask?: ShareUserDataTask }
type PersonsFilteredT = [PersonT[], PersonT[], PersonWithWorkflow[]]

const getTenantsFromPersonsList = (personsList: PersonT[]): TenantT[] =>
  Array.from(
    personsList
      .map((person) => person.tenant)
      .reduce((map, tenant) => (tenant && !map.has(tenant.id) ? map.set(tenant.id, tenant) : map), new Map())
      .values(),
  ) as TenantT[]

const filterPersonsWithTasksByTenant = (
  personsList: PersonT[],
  personsIdWithTasks: number[],
  tenantid: string,
  personTasks: Map<number, ShareUserDataTask>,
): PersonsFilteredT =>
  personsList.reduce(
    (filtered, person) => {
      if (person.tenant?.id !== tenantid) return filtered
      const [personsWithTasks, personsWithoutTasks, allPersons] = filtered
      const personId = person.id as number
      if (personsIdWithTasks.includes(personId)) {
        const task = personTasks.get(personId)
        return [
          [...personsWithTasks, person],
          personsWithoutTasks,
          [...allPersons, { ...person, shareUserDataTask: task }],
        ]
      }
      return [
        personsWithTasks,
        [...personsWithoutTasks, person],
        [...allPersons, { ...person, shareUserDataTask: undefined }],
      ]
    },
    [[], [], []] as PersonsFilteredT,
  )
const getTenant = (tenantList: TenantT[], tenantid: string) =>
  tenantList.find((tenant) => tenant.id === tenantid) as TenantT

const useRequestEmployeeData = () => {
  const [tenantList, setTenantList] = useState<TenantT[]>([])
  const [selectedTenant, setSelectedTenant] = useState<TenantT>()
  const [personsList, setPersonsList] = useState<PersonT[]>([])
  const [personsLoading, setPersonsLoading] = useState(true)
  const [tasksLoading, setTasksLoading] = useState(true)
  const [personWithTasksIdList, setPersonWithTasksIdList] = useState<number[]>([])
  const [personTasks, setPersonTasks] = useState<Map<number, ShareUserDataTask>>(new Map())
  const [[personsWithTasks, personsWithoutTasks, allPersons], setPersonsFiltered] = useState<PersonsFilteredT>([
    [],
    [],
    [],
  ])

  const fetchTenantPersons = useCallback(
    (tenantId: string) => {
      const filteredPersons = filterPersonsWithTasksByTenant(personsList, personWithTasksIdList, tenantId, personTasks)
      setPersonsFiltered(filteredPersons)
      setSelectedTenant(getTenant(tenantList, tenantId))
    },
    [personTasks, personWithTasksIdList, personsList, tenantList],
  )

  useJamesApolloQuery<{ findAllManagedPersons: PersonT[] }>(allManagedPersonsQuery, {
    variables: {
      teamPermissions: [teamPermissionTypes.shareUserDataManager],
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(managedPersons) {
      const persons = managedPersons.findAllManagedPersons
      const tenants = getTenantsFromPersonsList(persons)
      setTenantList(tenants)
      setPersonsList(persons)
      setPersonsLoading(false)
    },
  })

  useJamesApolloQuery<{ tasksPaginated: TasksPaginated }>(tasksPaginatedQuery, {
    skip: personsLoading,
    variables: {
      filters: {
        // eslint-disable-next-line camelcase
        task_types: [taskTypes.shareUserData],
        // eslint-disable-next-line camelcase
        person_ids: personsList.map((person) => person.id),
        // eslint-disable-next-line camelcase
        task_statuses: [taskStates.pending, taskStates.todo],
      },
      office: true,
    },
    fetchPolicy: 'no-cache',
    onCompleted(responseData) {
      const tasks = responseData.tasksPaginated.list
      const personsWithTasksIds = tasks.map((task) => task.person?.id as number)
      setPersonWithTasksIdList(personsWithTasksIds)
      setPersonTasks(
        tasks.reduce((state, task) => {
          return state.set(task.person?.id as number, task as ShareUserDataTask)
        }, new Map<number, ShareUserDataTask>()),
      )
      setTasksLoading(false)
    },
  })

  useEffect(() => {
    if (tasksLoading) return
    if (tenantList && tenantList.length > 0) {
      const selTenant = tenantList[0]
      setSelectedTenant(selTenant)
      fetchTenantPersons(selTenant.id)
    }
  }, [fetchTenantPersons, tasksLoading, tenantList])

  return {
    fetchTenantPersons,
    loading: personsLoading || tasksLoading,
    personsWithTasks,
    personsWithoutTasks,
    allPersons,
    tenantList,
    selectedTenant,
  }
}

export default useRequestEmployeeData
