import React from 'react'
import { Sheet } from 'react-modal-sheet'
import Dialog from 'redesign/components/organisms/Dialog'
import { ContentContainer, CustomSheet, FooterContainer } from './styles'
import type { FullScreenModalProps } from './types'

const FullScreenModal: React.FC<FullScreenModalProps> = ({
  children,
  disableDrag,
  rootId,
  isOpen,
  onClose,
  isSmallScreen,
  header,
  footer,
}) =>
  isSmallScreen ? (
    <CustomSheet disableDrag={disableDrag} rootId={rootId} onClose={onClose} isOpen={isOpen}>
      <Sheet.Container>
        <Sheet.Header>{header}</Sheet.Header>
        <Sheet.Content>
          <ContentContainer>{children}</ContentContainer>
          <FooterContainer>{footer}</FooterContainer>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} />
    </CustomSheet>
  ) : (
    // TODO: Placeholder
    <Dialog open={isOpen} onClose={onClose}>
      {header}
      <ContentContainer>{children}</ContentContainer>
      <FooterContainer>{footer}</FooterContainer>
    </Dialog>
  )

export default FullScreenModal
