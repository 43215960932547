import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE } from '../Icon/constants'
import type { IllustrationProps } from './types'

const FavurLogo: React.FC<IllustrationProps> = ({ width = 88, height = ICON_SIZE.medium, viewBox }) => (
  <Svg width={width} height={height} viewBox={viewBox}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.243 4.763c5.29 0 7.55 2.096 7.608 6.223l.002.164v6.419c0 1.323.342 1.838 1.125 2.118.24.085.45.109.627.102l.073-.005-3.465 3.465c-1.322-.333-2.269-.998-2.925-2.24-1.292 1.92-3.277 2.581-5.328 2.581-3.507 0-5.757-2.282-5.757-5.524 0-3.82 2.867-5.965 8.155-6.021l.215-.002h1.523v-.596C26.096 9.53 25.368 9 23.35 9c-.85 0-2.104.204-3.446.575l-.238.067-3.19-3.191c1.96-.954 5.636-1.687 7.767-1.687zm36.91.584V17.26c0 1.587.53 2.018 1.39 2.018.827 0 1.687-.596 2.383-1.754V5.347h5.888v17.635h-5.16l-.233-2.084c-1.223 1.82-2.977 2.68-5.26 2.68-3.343 0-4.897-2.183-4.897-5.558V5.347h5.889zm-20.179.012 2.648 13.302 2.845-13.302h5.988l-5.393 17.636h-6.98L34.59 5.36h6.384zM6.973 4.354V9.86h6.484v4.335H6.973v8.801H.85v-12.52l6.122-6.12zm78.364.463c.662 0 1.158.1 1.754.298l-.927 5.69c-.563-.164-1.025-.264-1.621-.264-2.118 0-2.945 1.59-3.475 4.07v8.371h-5.89V5.347h5.163l.496 3.342c.727-2.35 2.514-3.872 4.5-3.872zm-59.24 10.37h-.993c-2.117 0-3.111.76-3.111 2.315 0 1.191.63 1.952 1.755 1.952.948 0 1.747-.515 2.278-1.282l.07-.106v-2.88zM17.04 0l-4.334 4.334H6.992L11.327 0h5.714z"
      fill="#1F3867"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M.851 7.149V0H8L.851 7.149z" fill="#FF0080" />
  </Svg>
)

export default FavurLogo
