/* eslint-disable camelcase */
export const absencePeriods: { [key: string]: string } = {
  fullDay: 'absenceRequest.new.fullDay',
  halfDayMorning: 'absenceRequest.new.halfDayMorning',
  halfDayAfternoon: 'absenceRequest.new.halfDayAfternoon',
}

export type AbsencePeriodsT = 'full_day' | 'half_day_morning' | 'half_day_afternoon'
export const absencePeriodsTranslationMap: Record<string, string> = {
  full_day: 'fullDay',
  half_day_morning: 'halfDayMorning',
  half_day_afternoon: 'halfDayAfternoon',
}

export const modules: Record<string, string> = {
  absencesPlan: 'absences_plan',
  announcements: 'announcements',
  balances: 'balances',
  businessCustomizations: 'business_customizations',
  comments: 'comments',
  documents: 'documents',
  employeeCards: 'employee_cards',
  reminders: 'reminders',
  shiftPlan: 'shift_plan',
  tasks: 'tasks',
  teams: 'teams',
  user: 'user',
  userAvatar: 'user_avatar',
  workflowAbsences: 'workflow_absences',
  workflowMonthlySheets: 'workflow_monthly_sheets',
  workflowSecureConnection: 'workflow_secure_connection',
  workflowShareUserData: 'workflow_share_user_data',
  workflowShiftUpdate: 'workflow_shift_update',
}

export enum PersonStatusType {
  active = 'active',
  applicant = 'applicant',
  inactive = 'inactive',
}

export type TaskTypeT = 'absence_request' | 'share_user_data' | 'monthly_sheet' | 'shift_update' | 'secure_connection'
export const taskTypes: Record<string, TaskTypeT> = {
  absenceRequest: 'absence_request',
  shareUserData: 'share_user_data',
  monthlySheet: 'monthly_sheet',
  shiftUpdate: 'shift_update',
  secureConnection: 'secure_connection',
}
