import React from 'react'
import { IconButton } from '@mui/material'
import { CloseIcon } from 'redesign/components/atoms/Icon'
import { Container } from './styles'
import type { DrawerHeaderEndContentProps } from './types'

const DrawerHeaderEndContent: React.FC<DrawerHeaderEndContentProps> = ({ closeIconOnClick, children }) => (
  <Container>
    {children}
    <IconButton onClick={closeIconOnClick}>
      <CloseIcon />
    </IconButton>
  </Container>
)

export default DrawerHeaderEndContent
