import { groupBy } from 'lodash/fp'
import { TASK_TYPES } from '../../constants'
import { getContentStatus } from '../../getContentStatus'
import { getDateFormatFromTask } from '../Dates/getDateFormatFromTask'
import { getShiftUpdateDatePeriod } from '../Dates/getShiftUpdateDatePeriod'
import { EMPTY_CONTENT_DATA } from '../constants'
import type { GetTaskListData } from '../types'
import { getTaskState } from './getTaskState'
import { isShiftUpdateTask } from './taskDataTypeGuard'

export const getShiftUpdateData = ({ task, locale, t }: GetTaskListData) => {
  if (!isShiftUpdateTask(task)) return EMPTY_CONTENT_DATA

  const { status, updatedAt, statusFe, shiftsUpdatedHistory } = task

  // eslint-disable-next-line fp/no-mutating-methods
  const shiftDatesHistory = Object.keys(groupBy('date', shiftsUpdatedHistory ?? [])).sort()

  return {
    bodyText: getShiftUpdateDatePeriod({ shiftsUpdated: shiftDatesHistory, locale, t }),
    startText: t(getTaskState({ statusFe })),
    secondaryText: t(getContentStatus({ status, taskType: TASK_TYPES.shiftUpdate })),
    endText: getDateFormatFromTask({ date: updatedAt, locale, t }),
  }
}
