import { styled } from '@mui/material'

export const Container = styled(`div`)`
  display: flex;
  flex-direction: column;
`

export const TitleContainer = styled(`div`)`
  display: flex;
  padding: ${({ theme }) => `0 ${theme.spacing(2)} var(--2, ${theme.spacing(2)}) ${theme.spacing(2)}`};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(1)};
  align-self: stretch;
`

export const BodyContainer = styled(`div`)`
  display: flex;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  align-items: flex-start;
  align-content: flex-start;
  gap: ${({ theme }) => theme.spacing(1)};
  align-self: stretch;
  flex-wrap: wrap;
`
