import { useMemo, useEffect, useCallback, useState } from 'react'
import { AppNavigationProps } from 'components/Page/NavigationApp/types'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurNavigation from 'hooks/useFavurNavigation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import useRolesViews from 'hooks/useRolesViews'
import useSessionContext from 'hooks/useSessionContext'
import { useLocation } from 'react-router-dom'
import type { OnNavItemClick } from 'redesign/components/organisms/NavigationBarContent/types'

export const useNavigationBar = ({ hideSidePanel }: Pick<AppNavigationProps, 'hideSidePanel'>) => {
  const { auth } = useSessionContext()
  const { pathname } = useLocation()
  const { pushWithRole } = useHistoryUtils()
  const { activeView, setActiveView } = useRolesViews()
  const { nativeNavigation: mainNavigation, getDashboardIcon } = useFavurNavigation()
  const currentValue = useMemo(
    () =>
      mainNavigation.findIndex((navItem) => {
        const isActive = RegExp(`^${navItem.to}(/.*)?$`).test(pathname)
        // if navItem has no role defined, we expect the role to be correct regardless of view
        const isRoleCorrect = !navItem.role || navItem.role === activeView

        return isActive && isRoleCorrect
      }),
    [activeView, mainNavigation, pathname],
  )
  const DashboardIcon = getDashboardIcon(pathname)

  const [value, setValue] = useState(currentValue)

  useEffect(() => {
    setValue(currentValue)
  }, [currentValue])

  const onNavItemClick = useCallback(
    ({ newValue, to, role }: OnNavItemClick) => {
      setValue(newValue)
      if (to !== pathname) {
        pushWithRole(to, role)
        return
      }

      hideSidePanel()
      if (role && activeView !== role) {
        setActiveView(role ?? ROLE_VIEWS.frontliner)
      }
    },
    [activeView, hideSidePanel, pathname, pushWithRole, setActiveView],
  )

  return {
    auth,
    DashboardIcon,
    value,
    setValue,
    onNavItemClick,
    mainNavigation,
  }
}
