import type { GetLabelByLocale, GetTenantLinks } from '../types'

const getLabelByLocale = ({ link, locale }: GetLabelByLocale) => {
  switch (locale?.code) {
    case 'de':
      return link.labelDe
    case 'it':
      return link.labelIt
    case 'fr':
      return link.labelFr
    default:
      return link.labelEn
  }
}

export const getTenantLinks = ({ persons = [], locale }: GetTenantLinks) =>
  persons.flatMap((person) =>
    (person.tenant?.links ?? []).flatMap((link) =>
      link
        ? {
            title: getLabelByLocale({ link, locale }) ?? link.url ?? '',
            testId: `tenant-link_${link.id}`,
            onClick: () => window.open(link.url ?? '#', '_blank'),
          }
        : [],
    ),
  )
