import { useCallback, useState } from 'react'
import useFilterContext from 'components/Filter/context'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getISOFirstDayOfMonth, newDateWithoutTime } from 'utils/date'
import { DATE_PICKER_DEFAULT_VALUES } from '../../DatePicker/constants'
import type { DatePickerValues } from '../../DatePicker/types'
import type { DateRangeFilterProps } from './types'

export const useDateRangeFilter = ({ configuration }: DateRangeFilterProps) => {
  const { changeValue, getValue, validateFilters } = useFilterContext()
  const { t } = useFavurTranslation()
  const {
    maxDate: maxDateString,
    minDate: minDateString,
    fromDate: { name: fromDateName },
    toDate: { name: toDateName },
  } = configuration
  const fromDateValueString = getValue(fromDateName)
  const toDateValueString = getValue(toDateName)
  const maxDate = newDateWithoutTime(maxDateString)
  const minDate = newDateWithoutTime(minDateString)
  const fromDateValue = fromDateValueString ? newDateWithoutTime(fromDateValueString as string) : null
  const toDateValue = toDateValueString ? newDateWithoutTime(toDateValueString as string) : null
  const [fromDateValues, setFromDateValues] = useState({ ...DATE_PICKER_DEFAULT_VALUES, date: fromDateValue })
  const [toDateValues, setToDateValues] = useState({ ...DATE_PICKER_DEFAULT_VALUES, date: toDateValue })
  const error = !validateFilters() ? t('filter.task_time_frame.error_label') : undefined
  const startDateOnChange = useCallback(
    (updatedValues: DatePickerValues) => {
      if (updatedValues.date) {
        setFromDateValues(updatedValues)
        changeValue(fromDateName, getISOFirstDayOfMonth(updatedValues.date))
      }
    },
    [changeValue, fromDateName],
  )
  const endDateOnChange = useCallback(
    (updatedValues: DatePickerValues) => {
      if (updatedValues.date) {
        setToDateValues(updatedValues)
        changeValue(toDateName, getISOFirstDayOfMonth(updatedValues.date))
      }
    },
    [changeValue, toDateName],
  )

  return {
    endDateOnChange,
    error,
    fromDateName,
    fromDateValues,
    minDate,
    maxDate,
    startDateOnChange,
    toDateName,
    toDateValues,
  }
}
