import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const EventIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
}) => (
  <SvgWrapper viewBox={viewBox} size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.998 3.533-2.7 5.353a.732.732 0 0 1-.58.399l-5.171.512 4.265 4.224c.188.186.261.46.19.714l-1.58 5.741 5.251-2.6a.732.732 0 0 1 .65 0l5.258 2.604L16 14.739a.732.732 0 0 1 .19-.714L20.453 9.8l-5.17-.512a.732.732 0 0 1-.581-.399l-2.704-5.356zm8.559 6.165zm-9.293-7.491a1.406 1.406 0 0 1 1.986.558l.002.004 2.575 5.102 4.9.486a1.395 1.395 0 0 1 .86 2.38l-4.062 4.026 1.507 5.474a1.4 1.4 0 0 1-1.984 1.612l-5.05-2.5-5.042 2.497h-.003a1.401 1.401 0 0 1-1.982-1.612l.002-.007 1.505-5.467-4.065-4.026a1.395 1.395 0 0 1 .86-2.38l4.9-.486 2.571-5.099.002-.004c.118-.23.297-.423.518-.558z"
      fill={color}
      fillOpacity=".87"
    />
  </SvgWrapper>
)

export default EventIcon
