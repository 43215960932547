import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_TUTORIAL } from './constants'
import { IconPropsT } from './types'

const DocumentInfo: React.FC<IconPropsT> = ({ fill, size = ICON_SIZE_TUTORIAL }) => {
  return (
    <Svg size={size} fill={fill} viewBox="0 0 80 80">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.325 35.325a18.75 18.75 0 1 1 26.517 26.516 18.75 18.75 0 0 1-26.517-26.516ZM48.583 34a14.584 14.584 0 1 0 0 29.167 14.584 14.584 0 0 0 0-29.167Zm-2.21 5.251a3.125 3.125 0 1 1 4.42 4.42 3.125 3.125 0 0 1-4.42-4.42Zm2.21 7.249c1.15 0 2.084.933 2.084 2.083v8.334a2.083 2.083 0 0 1-4.167 0v-8.334c0-1.15.933-2.083 2.083-2.083Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.917 4.833a2.083 2.083 0 0 0-2.084 2.084V52.75a2.083 2.083 0 0 0 2.084 2.083h16.666a2.083 2.083 0 0 1 0 4.167H6.917a6.25 6.25 0 0 1-6.25-6.25V6.917a6.25 6.25 0 0 1 6.25-6.25h29.525c1.656 0 3.245.658 4.417 1.829l7.978 7.978a6.252 6.252 0 0 1 1.83 4.417v8.692a2.083 2.083 0 0 1-4.167 0v-8.69c0-.553-.22-1.083-.61-1.473l-7.977-7.977c-.39-.39-.92-.61-1.471-.61H6.917Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.694 17.333c0-1.15.933-2.083 2.084-2.083h25a2.083 2.083 0 1 1 0 4.167h-25a2.083 2.083 0 0 1-2.084-2.084Zm0 11.111c0-1.15.933-2.083 2.084-2.083h13.889a2.083 2.083 0 0 1 0 4.167h-13.89a2.083 2.083 0 0 1-2.083-2.084Zm0 13.89c0-1.151.933-2.084 2.084-2.084h8.333a2.083 2.083 0 1 1 0 4.167h-8.333a2.083 2.083 0 0 1-2.084-2.084Z"
      />
    </Svg>
  )
}

export default DocumentInfo
