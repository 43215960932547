import React from 'react'
import { IconProps } from 'redesign/components/atoms/Icon/types'
import { RoleViewT } from 'types'

export type NavPointIdT =
  | 'dashboard'
  | 'shifts'
  | 'balances'
  | 'documents'
  | 'tasks'
  | 'managerTasks'
  | 'personalTasks'
  | 'settings'
  | 'notifications'
  | 'logout'
  | 'contact'
  | 'appInfo'
  | 'privacy'
  | 'absencePlanner'
  | 'cmsOverview'

export const navPointIds: Record<string, NavPointIdT> = {
  dashboard: 'dashboard',
  shifts: 'shifts',
  balances: 'balances',
  documents: 'documents',
  tasks: 'tasks',
  managerTasks: 'managerTasks',
  personalTasks: 'personalTasks',
  settings: 'settings',
  notifications: 'notifications',
  logout: 'logout',
  contact: 'contact',
  appInfo: 'appInfo',
  privacy: 'privacy',
  absencePlanner: 'absencePlanner',
  cmsOverview: 'cmsOverview',
}

export type NavPointT = {
  id: NavPointIdT
  label: string
  labelShort?: string
  to: string
  icon?: React.FC<{
    fill?: string | undefined
  }>
  nativeIcon?: React.FC<{
    fill?: string | undefined
  }>
  redesignIcon?: React.FC<IconProps>
  testId: string
  role?: RoleViewT
}

export type NavPointConditionsT = { [key in NavPointIdT]: boolean }

export type NavPointCallbacksT = { [key in NavPointIdT]?: () => void }
