import { styled } from '@mui/material'
import { Sheet } from 'react-modal-sheet'

export const CustomSheet = styled(Sheet)`
  z-index: 20 !important;
`

export const ContentContainer = styled('div')`
  height: 100%;
  flex: 1;
  overflow: hidden;
`

export const FooterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: ${({ theme }) => theme.spacing(2)};
  margin-top: auto;
  bottom: 0;
  background: ${({ theme }) => theme.palette.background.default};
  border-top: ${({ theme }) => `${theme.spacing(0.0625)} solid ${theme.palette.common?.cardBorder}`};
`
