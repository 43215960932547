import { styled } from '@mui/material'

export const DateContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

export const DateSpan = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isToday',
})<{ isToday?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(0.75)};
  align-items: end;
  color: ${({ isToday, theme }) => (isToday ? theme.palette.primary.main : theme.palette.text.primary)};
`

export const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;
`

export const NoShiftContainer = styled('div')`
  display: flex;
`
