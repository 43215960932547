import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const CalendarShiftIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
  isFilled,
  fillOpacity,
}) => {
  const path = isFilled ? (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2a.75.75 0 0 1 .75.75V5h6.5V2.75a.75.75 0 0 1 1.5 0V5H20a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3.25V2.75A.75.75 0 0 1 8 2zm-1 8.25a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5H7zm7.25.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75zM12 13.25a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5h-5zM6.25 14a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75zM7 16.25a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5H7zm7.25.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  ) : (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2a.75.75 0 0 1 .75.75V5h6.5V2.75a.75.75 0 0 1 1.5 0V5H20a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3.25V2.75A.75.75 0 0 1 8 2zm-.75 4.5H4a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h16a.5.5 0 0 0 .5-.5V7a.5.5 0 0 0-.5-.5h-3.25v.25a.75.75 0 0 1-1.5 0V6.5h-6.5v.25a.75.75 0 0 1-1.5 0V6.5zM7 10.25a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5H7zm7.25.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75zM12 13.25a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5h-5zM6.25 14a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75zM7 16.25a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5H7zm7.25.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  )

  return (
    <SvgWrapper viewBox={viewBox} size={size} fill="none">
      {path}
    </SvgWrapper>
  )
}
export default CalendarShiftIcon
