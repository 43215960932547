import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FramedIcon from 'redesign/components/atoms/FramedIcon'
import Headline from 'redesign/components/atoms/Headline'
import { EmployeeCardIcon, PlaceholderIcon } from 'redesign/components/atoms/Icon'
import QuickLinkList from '../../QuickLinkList'
import { QuickLinksSectionProps } from './types'

const QuickLinksSection: React.FC<QuickLinksSectionProps> = ({ employeeCards = [], tenantLinks = [] }) => {
  const { t } = useFavurTranslation()

  return (
    <div>
      <Headline
        horizontalSpacing={0}
        text={tenantLinks.length ? t('page.dashboard.content.quickAccess') : t('page.dashboard.content.employeeCards')}
        testId="quick-links-section-headline"
      />
      <QuickLinkList list={employeeCards} defaultIcon={<FramedIcon icon={EmployeeCardIcon} />} />
      <QuickLinkList list={tenantLinks} defaultIcon={<FramedIcon icon={PlaceholderIcon} />} />
    </div>
  )
}

export default QuickLinksSection
