import { useMemo, useState, useCallback } from 'react'
import type { ContactFieldT } from 'components/Forms/Contact/types'
import type { PersonFieldT } from 'components/Forms/Family/types'
import type { FileTypeT } from 'components/Forms/PersonalData/types'
import type { FileFieldT } from 'components/Forms/components/FileSelect/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  UseFormProps,
  FieldPath,
  ChangeHandler,
  NestedValue,
  FieldValues,
  UnpackNestedValue,
  Resolver,
} from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useRefreshHighSecSession from './useRefreshHighSecSession'

type TFormConfig<T extends UnpackNestedValue<FieldValues>> = {
  context: string
  prolongHighSecuritySession?: boolean
  formFields: TFormFields[]
  onSubmit: SubmitHandler<T>
  onError?: SubmitErrorHandler<T>
}

type TFormFields = {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  init: string | number | Record<string, any> | boolean | null
  validation: yup.Lazy | undefined
  label?: string | undefined
}

type FormProps<TFormValues extends FieldValues> = {
  defaultValues?: UseFormProps<TFormValues>['defaultValues']
}

export type FormHandlersT = {
  helperText: string
  error: boolean
  name: string
  label: string
  onChange: ChangeHandler
  onBlur: ChangeHandler
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: (instance: any) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNestedFormHelpers = (nestedArray: Record<string, any>, fieldName: string) => {
  const n = fieldName.split('.')
  const value = nestedArray[n[0]]

  if (Array.isArray(value)) {
    const index = parseInt(n[1])
    return value[index] ? value[index][n[2]] : value[index]
  }
  return value
}

const REFRESH_SESSION_TIMEOUT = 30000

// eslint-disable-next-line @typescript-eslint/comma-dangle
const useFavurForm = <
  TFavurValues extends Record<
    string,
    | undefined
    | string
    | NestedValue<FileFieldT[]>
    | NestedValue<PersonFieldT[]>
    | NestedValue<ContactFieldT[]>
    | number
    | Date
    | null
    | boolean
    | FileTypeT
  >
>({
  context,
  prolongHighSecuritySession,
  formFields,
  onSubmit,
  onError,
}: TFormConfig<TFavurValues>) => {
  const { t } = useFavurTranslation()
  const { refreshHighSecSession } = useRefreshHighSecSession()

  const validatorSchema = useMemo(() => {
    const schemaStruct = formFields.reduce((acc, field) => {
      return { ...acc, [field.name]: field.validation }
    }, {})

    return yup.object(schemaStruct)
  }, [formFields])

  const initValues = useMemo(() => {
    return formFields.reduce((acc, field) => {
      return { ...acc, [field.name]: field.init }
    }, {}) as TFavurValues
  }, [formFields])

  const labels = useMemo(() => {
    return formFields.reduce((acc, field) => {
      return { ...acc, [field.name]: field.label }
    }, {}) as TFavurValues
  }, [formFields])

  // https://github.com/react-hook-form/react-hook-form/issues/2129
  // otherwise it complains about defaultValues type
  const typeHack = { defaultValues: initValues } as FormProps<TFavurValues>
  const form = useForm<TFavurValues>({
    ...typeHack,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: yupResolver(validatorSchema, { abortEarly: false }) as Resolver<TFavurValues, any>,
    mode: 'onBlur',
    shouldFocusError: true,
    reValidateMode: 'onChange',
  })

  const [lastTimeHighSecUpdatedMs, setLastTimeHighSecUpdatedMs] = useState(0)

  const hasToRefreshHighSecurity = useCallback(
    (currentTimeInMs: number) => currentTimeInMs - lastTimeHighSecUpdatedMs > REFRESH_SESSION_TIMEOUT,
    [lastTimeHighSecUpdatedMs],
  )

  const {
    register,
    formState: { errors },
  } = form

  const getFormHandlers = useCallback<(fieldName: FieldPath<TFavurValues>) => FormHandlersT>(
    (fieldName: FieldPath<TFavurValues>) => {
      const { onChange, onBlur, name, ref } = register(fieldName)
      const error = getNestedFormHelpers(errors, fieldName)

      const labelNameArray = fieldName.split('.')
      const labelName = labelNameArray.length === 3 ? labelNameArray[2] : labelNameArray[0]

      return {
        helperText: error?.message ? error.message : ' ',
        error: Boolean(error),
        name,
        onChange: (e) => {
          if (prolongHighSecuritySession && hasToRefreshHighSecurity(Date.now())) {
            refreshHighSecSession()
            setLastTimeHighSecUpdatedMs(Date.now())
          }
          return onChange(e)
        },
        onBlur: (e) => {
          if (prolongHighSecuritySession && hasToRefreshHighSecurity(Date.now())) {
            refreshHighSecSession()
            setLastTimeHighSecUpdatedMs(Date.now())
          }

          return onBlur(e)
        },
        inputRef: ref,
        label: (labels[fieldName] ?? t(`${context}.${labelName}.label`)) as string,
      }
    },
    [register, errors, labels, t, context, prolongHighSecuritySession, hasToRefreshHighSecurity, refreshHighSecSession],
  )

  return {
    favurForm: {
      ...form,
      handleSubmit: form.handleSubmit(onSubmit, onError),
    },
    getFormHandlers,
  }
}

export default useFavurForm
