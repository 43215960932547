import React from 'react'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import QuickLinksSection from 'redesign/components/organisms/DashboardSections/QuickLinksSection'
import ShiftsSection from 'redesign/components/organisms/DashboardSections/ShiftsSection'
import TasksSection from 'redesign/components/organisms/DashboardSections/TasksSection'
import { Container } from './styles'
import type { DashboardContentProps } from './types'

const DashboardContent: React.FC<DashboardContentProps> = ({
  shiftsSection,
  taskSection,
  favurPackages,
  isManager,
  isLoading,
  quickLinksSection,
}) => {
  const { hasClassicLightAccess, hasClassicAccess } = favurPackages

  if (isLoading) {
    // TODO: Placeholder
    return <LoadingSpinner />
  }

  return (
    <Container>
      {hasClassicLightAccess ? <TasksSection {...taskSection} isManager={isManager} /> : null}
      {hasClassicAccess ? <ShiftsSection {...shiftsSection} /> : null}
      {quickLinksSection.showSection ? <QuickLinksSection {...quickLinksSection} /> : null}
    </Container>
  )
}

export default DashboardContent
