import React, { useMemo } from 'react'
import { FormProvider } from 'components/Forms/provider'
import useStepper from 'hooks/useStepper'
import { ShareUserDataTaskT, TaskT } from 'pages/Tasks/types'
import routes from 'services/RoutesProvider/routes'
import { userDataIntroStep, userDataSteps } from '../../Forms/constants'
import FormPage from './FormPage'

interface IShareUserDataFormProps {
  onClose: () => void
  task: TaskT<ShareUserDataTaskT> | undefined
}

const ShareUserDataForm: React.FC<IShareUserDataFormProps> = ({ onClose, task }) => {
  const { activeStep, goToStep } = useStepper({
    route: routes.userDataDetails,
    step: 'INTRO',
    steps: userDataSteps,
    extraDefaultStep: userDataIntroStep,
    checkAdditionalParams: false,
  })

  const favurUuid = useMemo(() => task?.favurUuid ?? '', [task])

  return (
    <FormProvider
      includeFiles={false}
      onUpdateBankData={() => goToStep('sharing', [favurUuid])}
      onUpdateContactData={() => goToStep('bank', [favurUuid])}
      onUpdateFamilyData={() => goToStep('contact', [favurUuid])}
      onUpdatePersonalData={() => goToStep('family', [favurUuid])}
      step={activeStep}
    >
      <FormPage
        onClose={onClose}
        activeStep={activeStep}
        goToStep={goToStep}
        showCommentButton={task?.timeline !== undefined && task.timeline.length > 0}
      />
    </FormProvider>
  )
}

export default ShareUserDataForm
