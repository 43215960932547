import React from 'react'
import { Typography } from '@mui/material'
import { getBadgeLabel } from 'components/Badge/helpers'
import Tag from 'redesign/components/atoms/Tag'
import AbsenceTag from 'redesign/components/atoms/Tag/AbsenceTag'
import { TAG_SIZE } from 'redesign/components/atoms/Tag/constants'
import type { TShiftTag } from './types'

export const ShiftTag: React.FC<TShiftTag> = ({ shift }) => {
  const { type, hexBackgroundColor, hexTextColor } = shift

  if (type === 'absence') {
    return <AbsenceTag tagSize={TAG_SIZE.small} fullWidth />
  }

  return (
    <Tag tagSize={TAG_SIZE.small} backgroundColor={hexBackgroundColor} fullWidth noPadding>
      <Typography variant="chipSmall" color={hexTextColor} noWrap>
        {getBadgeLabel(shift)}
      </Typography>
    </Tag>
  )
}
