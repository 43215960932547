import { NotificationPropValue } from '../types'

export const notificationGlosssaryKeys: Record<string, NotificationPropValue> = {
  absenceRequestCommentForFrontliner: {
    title: 'notification.new.absence.frontliner.comment.title',
    body: 'notification.comment.body',
  },
  absenceRequestCommentForManager: {
    title: 'notification.new.absence.manager.comment.title',
    body: 'notification.comment.body',
  },
  absenceRequestRequestDeletion: {
    title: 'notification.new.absence.deletion.title',
    body: 'notification.new.absence_singleday.body',
  },
  absenceRequestRequestDeletionMultiday: {
    title: 'notification.new.absence.deletion.title',
    body: 'notification.new.absence.dates_isMultiday.body',
  },
  document: {
    title: 'notification.new.document.title',
    body: 'notification.new.document.body',
  },
  documentMonthlySheetApproval: {
    title: 'notification.new.monthlySheetApproval.title',
    body: 'notification.new.monthlySheetApproval.body',
  },
  invitation: {
    title: 'notification.new.invitation.title',
    body: 'notification.new.invitation.body',
  },
  linkMessage: {
    title: 'notification.new.linkMessage.title',
    body: 'notification.new.linkMessage.body',
  },
  monthlySheetCommentForFrontliner: {
    title: 'notification.new.timeapproval.manager.comment.title',
    body: 'notification.comment.body',
  },
  monthlySheetCommentForManager: {
    title: 'notification.new.timeapproval.frontliner.comment.title',
    body: 'notification.comment.body',
  },
  officeAbsenceRequest: {
    title: 'notification.new.absence.requested.title',
    body: 'notification.new.absence.requested.body',
  },
  officeMonthlySheetApproved: {
    title: 'notification.new.timeapproval.accepted.title',
    body: 'notification.new.timeapproval.accepted.body',
  },
  receivedUserData: {
    title: 'notification.new.sharedata.sent.title',
    body: '',
  },
  reminder: {
    title: 'reminder.monthlySheet.title',
    body: 'reminder.monthlySheet.body',
  },
  reminderAbsenceRequestManualForManager: {
    title: 'notification.push.headline.manual.frontliner.reminder',
    body: 'notification.new.absence.manual.reminder.body',
  },
  reminderMonthlySheetManual: {
    title: 'notification.push.headline.manual.reminder',
    body: 'notification.new.worktimecontrol.manual.reminder.body',
  },
  reminderMonthlySheetManualForManager: {
    title: 'notification.push.headline.manual.frontliner.reminder',
    body: 'notification.new.worktimecontrol.manual.reminder.frontliner.body',
  },
  reminderSecureConnection: {
    title: 'notification.secureconnection.reminder.title',
    body: 'notification.secureconnection.reminder.body',
  },
  reminderSecureConnectionManualForFrontliner: {
    title: 'notification.push.headline.manual.reminder',
    body: 'notification.secureconnection.reminder.body',
  },
  reminderShareUserData: {
    title: 'notification.push.reminder.headline',
    body: 'notification.sharedata.reminder.body',
  },
  reminderShareUserDataManual: {
    title: 'notification.push.headline.manual.reminder',
    body: 'notification.sharedata.requested.manual.reminder.body',
  },
  reminderShareUserDataManualForManager: {
    title: 'notification.push.headline.manual.frontliner.reminder',
    body: 'notification.sharedata.requested.manual.frontliner.reminder.body',
  },
  reminderShiftUpdate: {
    title: 'notification.new.shiftchange.reminder.title',
    body: 'notification.new.shiftchange.reminder.body',
  },
  reminderShiftUpdateManual: {
    title: 'notification.push.headline.manual.reminder',
    body: 'notification.new.shiftchange.manual.reminder.body',
  },
  resolvedAbsenceRequest: {
    title: 'notification.new.resolvedAbsenceRequest.title',
    body: 'notification.new.resolvedAbsenceRequest.body',
  },
  resolvedAbsenceRequestRequestDeletion: {
    title: 'notification.new.absence.deletion.accepted.title',
    body: 'notification.new.absence.dates_multiday.body',
  },
  resolvedAbsenceRequestRequestDeletionDeclined: {
    title: 'notification.new.absence.deletion.declined.title',
    body: 'notification.new.absence_singleday.body',
  },
  secureConnection: {
    title: 'notification.new.secureconnection.title',
    body: 'notification.new.secureconnection.tenant',
  },
  secureConnectionCommentForFrontliner: {
    title: 'notification.secureconnection.frontliner.comment.title',
    body: 'notification.comment.body',
  },
  secureConnectionCommentForManager: {
    title: 'notification.secureconnection.manager.comment.title',
    body: 'notification.comment.body',
  },
  secureConnectionCompleted: {
    title: 'notification.new.secureconnection.completed',
    body: 'notification.new.secureconnection.tenant',
  },
  shareUserData: {
    title: 'notification.new.sharedata.requested.title',
    body: 'notification.new.sharedata.tenant',
  },
  shareUserDataAccepted: {
    title: 'notification.new.sharedata.accepted.title',
    body: 'notification.new.sharedata.tenant',
  },
  shareUserDataCommentForFrontliner: {
    title: 'notification.sharedata.frontliner.comment.title',
    body: 'notification.comment.body',
  },
  shareUserDataCommentForManager: {
    title: 'notification.sharedata.manager.comment.title',
    body: 'notification.comment.body',
  },
  shiftInserted: {
    title: 'notification.new.shiftInserted.title',
    body: 'notification.new.shiftInserted.body',
  },
  shiftUpdateSeen: {
    title: 'notification.new.shiftchangeseen.title',
    body: 'notification.new.shiftchangeseen.singleday.body',
  },
  shiftUpdateSeenMultiday: {
    title: 'notification.new.shiftchangeseen.title',
    body: 'notification.shiftUpdate.multipleChanges.body',
  },
  shiftUpdated: {
    title: 'notification.new.shiftUpdated.title',
    body: 'notification.new.shiftUpdated.body',
  },
  resignationInfo: {
    title: 'notification.resignation.info.future.title',
    body: 'notification.resignation.info.body',
  },
  resignationInfoPast: {
    title: 'notification.resignation.info.past.title',
    body: 'notification.resignation.info.body',
  },
}
