import { DOCUMENT_TYPES } from 'constants/documents'
import { TFunction } from 'react-i18next'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDateRange } from 'redesign/shared/date/utils'
import routes from 'services/RoutesProvider/routes'
import { DocumentNotification } from 'shared/graphql/graphql'
import { newDateWithoutTime } from 'utils/date'
import { GetNotificationData, HandleDocumentData } from '../types'

export const getBodyTranslation = (t: TFunction, documentType: string | undefined) => {
  if (documentType && documentType in DOCUMENT_TYPES) {
    const translatedDocumentType = t(
      `page.documents.documentType.${DOCUMENT_TYPES[documentType as keyof typeof DOCUMENT_TYPES]}`,
    )

    return t(`notification.new.document.body`, {
      documentType: translatedDocumentType,
    })
  }

  return t('notification.new.document.body_other_document_type')
}

const getReminderTitleTranslations = ({ t, data }: HandleDocumentData): string => {
  const { dateTo, dateFrom } = data
  if (Boolean(dateTo) && Boolean(dateFrom)) {
    return t('notification.new.worktimecontrol.title')
  }

  return t('notification.new.worktimecontrol.reminder.title')
}

const getReminderBodyTranslations = ({ t, data, locale }: HandleDocumentData): string => {
  const { dateTo, dateFrom } = data

  if (dateTo && Boolean(dateTo) && Boolean(dateFrom) && dateFrom) {
    const dateFormat = DATE_FORMAT_VARIANTS.shortWithoutWeekday

    const body = formatDateRange({
      dates: {
        startDate: newDateWithoutTime(dateFrom),
        endDate: newDateWithoutTime(dateTo),
      },
      dateFormat,
      locale,
      t,
    })

    return body
  }

  return t('notification.new.worktimecontrol.reminder.body')
}

const getDocumentBody = ({ t, data, locale }: HandleDocumentData): string => {
  if (data.documentType === DOCUMENT_TYPES.monthlySheetApproval) return getReminderBodyTranslations({ t, data, locale })

  return getBodyTranslation(t, data.documentType ?? undefined)
}

const getDocumentTitle = ({ t, data }: HandleDocumentData): string => {
  if (data.documentType === DOCUMENT_TYPES.monthlySheetApproval) return getReminderTitleTranslations({ t, data })

  return t(`notification.new.document.title`)
}

const getDocumentRoute = ({ documentType, taskUuid }: HandleDocumentData['data']) => {
  if (documentType === DOCUMENT_TYPES.monthlySheetApproval && taskUuid) {
    return routes.monthlySheetsWithTask(taskUuid)
  }

  return routes.documents
}

export const getDocumentData = ({ notification, t, locale }: GetNotificationData) => {
  const data = notification.data as DocumentNotification

  return {
    title: getDocumentTitle({ t, data }),
    body: getDocumentBody({ t, data, locale }),
    route: getDocumentRoute(data),
  }
}
