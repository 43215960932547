import React from 'react'
import { FilterProvider } from 'components/Filter/context'
import type { TaskStateT } from 'pages/Tasks/types'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import TaskListHeader from 'redesign/components/organisms/Headers/TaskListHeader'
import { TaskList } from 'redesign/components/templates/ContentLists'
import Filters from 'redesign/components/templates/Filters'
import Page from 'redesign/components/templates/Page'
import { TASK_LIST_ROOT_ID } from './constants'
import { useTaskList } from './useTaskList'

const TaskListPage: React.FC<RouteComponentProps<{ taskstate?: TaskStateT }>> = () => {
  const { list: tasks, isOffice, loading, loadMore, showLoadMoreButton } = useTaskList()
  const history = useHistory()
  const redirect = ({ taskUrl }: { taskUrl: string }) => history.push(taskUrl)

  return (
    <FilterProvider configuration={[]} name="tasks">
      <Page rootId={TASK_LIST_ROOT_ID} header={<TaskListHeader isOffice={isOffice} />} paddingX={0}>
        <TaskList
          isLoading={loading}
          isOffice={isOffice}
          tasks={tasks}
          redirect={redirect}
          showLoadMoreButton={showLoadMoreButton}
          loadMore={loadMore}
        />
      </Page>
      <Filters rootId={TASK_LIST_ROOT_ID} />
    </FilterProvider>
  )
}
export default TaskListPage
