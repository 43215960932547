import { ROLE_VIEWS } from 'constants/roles'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDateRange } from 'redesign/shared/date/utils'
import routes from 'services/RoutesProvider/routes'
import { absencePeriods, absencePeriodsTranslationMap } from 'shared/constants'
import { OfficeAbsenceRequestNotification } from 'shared/graphql/graphql'
import { newDateWithoutTime } from 'utils/date'
import { getShortNameFromName } from 'utils/person'
import type { GetNotificationData, GetOfficeAbsenceRequestBody } from '../types'
import { notificationGlosssaryKeys } from './constants'

const getOfficeAbsenceRequestBody = ({ data, t, locale }: GetOfficeAbsenceRequestBody) => {
  const dateFormat = DATE_FORMAT_VARIANTS.shortWithoutWeekday
  const { dateFrom, dateTo, period } = data
  const isMultiday = dateFrom !== dateTo
  const absencePeriod = isMultiday || !period?.trim() ? '' : t(absencePeriods[absencePeriodsTranslationMap[period]])
  const dateRange = formatDateRange({
    dates: {
      startDate: newDateWithoutTime(dateFrom),
      endDate: isMultiday ? newDateWithoutTime(dateTo) : undefined,
    },
    dateFormat,
    locale,
    t,
  })

  return isMultiday
    ? dateRange
    : t('notification.new.absence_singleday.body', {
        dateFrom: dateRange,
        absencePeriod,
      })
}

export const getOfficeAbsenceRequestData = ({ notification, t, locale }: GetNotificationData) => {
  const data = notification.data as OfficeAbsenceRequestNotification
  const { taskUuid, userName, userNameShort } = data

  return {
    title: t(notificationGlosssaryKeys.officeAbsenceRequest.title, {
      frontlinerNameShort: userNameShort ?? getShortNameFromName(userName ?? ''),
    }),
    body: getOfficeAbsenceRequestBody({ data, t, locale }),
    route: `${routes.absenceRequestDetail}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
