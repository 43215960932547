import { styled } from '@mui/material'
import { IonPicker as ReactIonPicker } from '@ionic/react'

export const IonPicker = styled(ReactIonPicker)`
  --highlight-background: ${({ theme }) => theme.palette.primary.selected};
  --highlight-border-radius: ${({ theme }) => theme.spacing(1)};

  .option-active {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`
