import React from 'react'
import { Typography } from '@mui/material'
import { Container, TitleContainer, BodyContainer } from './styles'
import type { FilterSectionProps } from './types'
import { getConfigurationComponent } from './utils'

const FilterSection: React.FC<FilterSectionProps> = ({ configuration }) => (
  <Container>
    <TitleContainer>
      <Typography variant="overline">{configuration.label}</Typography>
    </TitleContainer>
    <BodyContainer>{getConfigurationComponent(configuration)}</BodyContainer>
  </Container>
)

export default FilterSection
