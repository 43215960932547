import MuiBadge from './MuiBadge'
import MuiButton from './MuiButton'
import MuiCheckbox from './MuiCheckbox'
import MuiFormLabel from './MuiFormLabel'
import MuiIconButton from './MuiIconButton'
import MuiLink from './MuiLink'
import MuiTab from './MuiTab'
import MuiTabs from './MuiTabs'
import MuiTextField from './MuiTextField'

const ComponentOverrides = {
  MuiBadge,
  MuiCheckbox,
  MuiFormLabel,
  MuiTab,
  MuiTabs,
  MuiButton,
  MuiIconButton,
  MuiLink,
  MuiTextField,
}

export { ComponentOverrides }
