import React from 'react'
import Chip from 'redesign/components/atoms/Chip'
import { Container } from './styles'
import type { MultipleChoiceChipGroupProps } from './types'

const MultipleChoiceChipGroup: React.FC<MultipleChoiceChipGroupProps> = ({ options, onSelect, isChecked }) => (
  <Container>
    {options.map(({ label, name }) => (
      <Chip key={name} label={label} onClick={() => onSelect(name)} selected={isChecked(name)} />
    ))}
  </Container>
)

export default MultipleChoiceChipGroup
