import { ListItemIcon, ListItemText, styled } from '@mui/material'
import { motion } from 'framer-motion'

export const ListGroup = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0;
  border-bottom: ${({ theme }) => `${theme.spacing(0.125)} solid ${theme.palette.common.navItemBase}`};
  gap: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  list-style: none;
`

export const NavItem = styled(motion.li)`
  padding: 0;
  margin: 0;
  min-height: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  cursor: pointer;

  &:hover,
  &:focus,
  &:hover:before {
    background-color: 'transparent';
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.selected};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.common.hoverNavItem};
  }
`
export const IconItem = styled(ListItemIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.spacing(2)};
  min-width: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(0.75)};
`

export const ItemText = styled(ListItemText)`
  margin: 0;
`

export const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.spacing(5)};
  height: ${({ theme }) => theme.spacing(5)};
`
