import { styled } from '@mui/material'

export const QuickLinkListContainer = styled('div')`
  display: flex;
  flex-flow: row wrap;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};

  :last-of-type {
    padding-bottom: 0;
  }
`
export const QuickListItemContainer = styled('div')`
  display: flex;
  // This is a calculation to cleanly split list items into two columns, taking the gap into consideration
  width: calc(50% - ${({ theme }) => theme.spacing(1)});

  // The last element should take the full width if it has no pair element
  :last-of-type {
    :nth-of-type(odd) {
      width: 100%;
    }
  }
`
