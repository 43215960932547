import { useEffect, useMemo } from 'react'
import useFilterContext from 'components/Filter/context'
import type { PersonsT } from 'components/Filter/types'
import type { RemovableChipOption } from 'redesign/components/molecules/ChipGroups/RemovableChipGroup/types'
import { PersonT } from 'types'
import { getUserName } from 'utils/person'
import type { PersonFilterProps } from './types'

const usePersonsFilter = (configuration: PersonFilterProps['configuration']) => {
  const {
    getValue,
    clearPersonListElement,
    openPersonsList,
    clearPersonList,
    getPersonsListSelected,
    getConfiguration,
    cancelPersonsList,
    setCurrentSelectedPersons,
  } = useFilterContext()

  const selectedPersonIds = useMemo(() => (getValue(configuration.name) as number[]) || [], [
    getValue,
    configuration.name,
  ])

  useEffect(() => {
    setCurrentSelectedPersons(selectedPersonIds)
  }, [selectedPersonIds, setCurrentSelectedPersons])

  const selectedPersons = useMemo(
    () =>
      selectedPersonIds.reduce<PersonT[]>((acc, personId) => {
        const person = configuration.options.find((pp) => pp.id === personId)
        return person ? [...acc, person] : acc
      }, []),
    [configuration.options, selectedPersonIds],
  )

  const selectedPersonsForChips: RemovableChipOption[] = useMemo(
    () =>
      selectedPersons.map((person) => ({
        label: getUserName(person),
        value: person.id ?? 0,
      })),
    [selectedPersons],
  )
  const personsListSelected = getPersonsListSelected()
  const personListOption = useMemo(
    () => getConfiguration().find((option) => option.name === personsListSelected) as PersonsT,
    [getConfiguration, personsListSelected],
  )

  return {
    cancelPersonsList,
    clearPersonList,
    clearPersonListElement,
    openPersonsList,
    personListOption,
    personsListSelected,
    selectedPersonsForChips,
    selectedPersons,
  }
}

export default usePersonsFilter
