import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const Search: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
}) => (
  <SvgWrapper viewBox={viewBox} size={size}>
    <path
      d="M9.927 2a8 8 0 0 1 6.235 13.102l5.618 5.618a.75.75 0 1 1-1.06 1.06l-5.618-5.618a7.996 7.996 0 0 1-8.095 1.257A8 8 0 0 1 9.927 2Zm2.505 1.972a6.5 6.5 0 1 0-4.864 12.056 6.5 6.5 0 0 0 4.864-12.056Z"
      fill={color}
    />
  </SvgWrapper>
)

export default Search
