import React from 'react'
import { useTheme } from '@mui/material'
import { isNative } from 'utils/platform'
import Link from '../../atoms/Link'
import { Content, MuiCard } from './styles'
import type { CardProps } from './types'

const Card: React.FC<CardProps> = ({ children, download, isNew = false, url, onClick, dataTestId }) => {
  const theme = useTheme()

  return (
    <Link url={url} download={download}>
      <MuiCard
        data-testid={dataTestId}
        aria-label="card"
        isNew={isNew}
        theme={theme}
        onClick={onClick}
        isClickable={Boolean(onClick)}
        isNative={isNative()}
      >
        <Content>{children}</Content>
      </MuiCard>
    </Link>
  )
}

export default Card
