import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const Bell: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
}) => (
  <SvgWrapper viewBox={viewBox} size={size} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 2.25a.75.75 0 0 1 .75.75v1.092a6.75 6.75 0 0 1 6 6.708c0 2.771.295 4.34.564 5.187.134.423.26.66.332.775.032.05.053.077.062.087a.75.75 0 0 1-.509 1.302H5.3a.75.75 0 0 1-.592-1.212v.001l-.001.002-.003.003-.003.004-.001.002v-.001l.018-.026c.02-.031.054-.09.1-.18.09-.18.225-.488.363-.956.276-.935.569-2.512.569-4.988a6.75 6.75 0 0 1 6-6.708V3a.75.75 0 0 1 .75-.75Zm-6.022 14.4h11.976a7.027 7.027 0 0 1-.07-.209c-.33-1.042-.635-2.775-.635-5.64a5.25 5.25 0 1 0-10.5 0c0 2.591-.307 4.315-.63 5.412a9.207 9.207 0 0 1-.141.438Zm4.21 2.431a.75.75 0 0 1 .93.508.92.92 0 0 0 1.765 0 .75.75 0 1 1 1.44.423 2.42 2.42 0 0 1-4.644 0 .75.75 0 0 1 .509-.931Z"
      fill={color}
    />
  </SvgWrapper>
)

export default Bell
