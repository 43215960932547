import { Badge, styled } from '@mui/material'

export const BadgeContainer = styled(Badge)`
  flex: 1;
  border: ${({ theme }) => `${theme.spacing(0.0625)} solid ${theme.palette.common?.cardBorder}`};
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.palette.common.hoverCard};
  }
  background-color: ${({ theme }) => theme.palette.background.paper};
  margin-bottom: 0 !important;

  & .MuiBadge-standard {
    top: ${({ theme }) => theme.spacing(0.5)};
    right: ${({ theme }) => theme.spacing(0.5)};
  }
`
