import { styled } from '@mui/material'
import { motion } from 'framer-motion'

export const NavigationButtonsContainer = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== 'isNative',
})<{ isNative?: boolean }>`
  display: flex;
  align-items: center;
  min-height: ${({ isNative, theme }) => (isNative ? theme.spacing(10) : 0)};
  color: ${({ theme }) => theme.palette.common.white};
`

export const ButtonGroup = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${({ theme }) => theme.spacing(3)};
  padding-left: 0;
  list-style: none;
`
