import React from 'react'
import { useTheme } from '@mui/material'
import { ChevronRight } from 'redesign/components/atoms/Icon'
import IconAndTextBlock from '../IconAndTextBlock'
import { Container } from './styles'
import type { HeaderBlockProps } from './types'

const HeaderBlock: React.FC<HeaderBlockProps> = ({ icon, isLink, title, component }) => {
  const theme = useTheme()

  return (
    <Container>
      <IconAndTextBlock icon={icon} title={title} />
      {isLink ? <ChevronRight color={theme.palette.primary.main} /> : component}
    </Container>
  )
}

export default HeaderBlock
