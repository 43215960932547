import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { Container } from './styles'
import type { AcknowledgeAllButtonProps } from './types'

const AcknowledgeAllButton: React.FC<AcknowledgeAllButtonProps> = ({ onClick, testId }) => {
  const { t } = useFavurTranslation()

  return (
    <Container>
      <Button variant="outlined" color="inherit" onClick={onClick} data-testid={testId}>
        {t('page.notifications.list.acknowledgeAll')}
      </Button>
    </Container>
  )
}

export default AcknowledgeAllButton
