import { Page } from 'redesign/components/organisms/Tabs/types'

export const PAGE_KEYS = {
  supervisor: 'supervisor',
  personal: 'personal',
}

export const PAGES: Page[] = [
  {
    name: PAGE_KEYS.supervisor,
    label: 'page.notifications.tabs.manager',
  },
  {
    name: PAGE_KEYS.personal,
    label: 'page.notifications.tabs.personal',
  },
]
