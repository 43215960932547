import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Chip, Typography } from '@mui/material'
import Badge from 'components/Badge'
import EventCount from 'components/Cms/EventCount'
import { ChangeAlertS } from 'icons'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { CmsContentT, PlannedShiftT } from 'types'
import { getShiftKey } from 'types/utils'
import theme from 'utils/theme'
import { hasAcknowledgedShiftUpdate } from '../utils'
import { classes } from './styles'

interface IShiftContentProps {
  day: string
  sortedShifts: PlannedShiftT[]
  shiftUpdates: TaskT<ShiftUpdateTaskT>[]
  events?: CmsContentT[]
  containerRef: React.RefObject<HTMLDivElement>
}

const getMaxChips = (containerHeight: number | null) => {
  const chipHeight = 18
  const labelHeigth = 25
  if (!containerHeight) return 0
  return Math.floor((containerHeight - labelHeigth) / chipHeight)
}

const CalendarDayShifts: React.FC<IShiftContentProps> = ({ day, sortedShifts, shiftUpdates, events, containerRef }) => {
  const [maxChips, setMaxChips] = useState(0)
  const [hasOverflow, setHasOverflow] = useState(false)
  const [shiftsToShow, setShiftsToShow] = useState<PlannedShiftT[]>([])
  const numEvents = useMemo(() => (!events?.length ? 0 : 1), [events])

  const hasAcknowledgedTask = useCallback((shift: PlannedShiftT) => hasAcknowledgedShiftUpdate(shift, shiftUpdates), [
    shiftUpdates,
  ])

  useEffect(() => {
    const maxChipsNum = getMaxChips(containerRef?.current?.clientHeight ?? 0)
    setMaxChips(maxChipsNum)
  }, [containerRef])

  useEffect(() => {
    const callback = () => {
      const maxChipsNum = getMaxChips(containerRef?.current?.clientHeight ?? 0)
      setMaxChips(maxChipsNum)
    }

    window.removeEventListener('resize', callback)
    window.addEventListener('resize', callback)

    return () => {
      window.removeEventListener('resize', callback)
    }
  }, [containerRef, sortedShifts])

  useEffect(() => {
    const hasOverflowVal = sortedShifts.length + numEvents > maxChips
    setHasOverflow(hasOverflowVal)
    const chipsToShow = hasOverflowVal ? maxChips - numEvents - 1 : maxChips - numEvents
    setShiftsToShow(sortedShifts.slice(0, chipsToShow))
  }, [maxChips, numEvents, sortedShifts])

  return (
    <>
      {shiftsToShow.map((shift) =>
        hasAcknowledgedTask(shift) ? (
          <Badge shift={shift} key={getShiftKey(shift)} sx={classes.badge} mini />
        ) : (
          <Chip
            key={getShiftKey(shift)}
            label={<ChangeAlertS fill={theme.palette.primary.main} />}
            sx={classes.unacknowledgedChip}
          />
        ),
      )}
      {events && numEvents > 0 && (
        <Chip
          key="events"
          data-testid={`calendar-event-badge-day-${day}`}
          label={<EventCount numberOfEvents={events.length} />}
          sx={classes.eventsChip}
        />
      )}
      {hasOverflow && <Typography variant="body2">...</Typography>}
    </>
  )
}

export default CalendarDayShifts
