import { HEADLINE_LEVELS } from './types'

export const getSpacingByLevel = (level: keyof typeof HEADLINE_LEVELS, noBottomSpacing: boolean) => {
  if (noBottomSpacing) {
    return 0
  }

  switch (level) {
    case HEADLINE_LEVELS.first:
      return 3
    case HEADLINE_LEVELS.second:
      return 2
    case HEADLINE_LEVELS.third:
      return 1
    default:
      return 2
  }
}

export const getVariantByLevel = (level: keyof typeof HEADLINE_LEVELS) => {
  switch (level) {
    case HEADLINE_LEVELS.first:
      return 'subtitle1'
    case HEADLINE_LEVELS.second:
      return 'overline'
    case HEADLINE_LEVELS.third:
      return 'subtitle2'
    default:
      return 'overline'
  }
}
