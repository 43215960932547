import routes from 'services/RoutesProvider/routes'
import type { GetEmployeeCards } from '../types'

export const getEmployeeCards = ({ persons = [], settings, history }: GetEmployeeCards) =>
  persons
    .filter(({ favurUuid }) => favurUuid && (settings?.dashboardEmployeeCards ?? []).includes(favurUuid))
    .map(({ favurUuid, tenant }) => ({
      title: tenant?.name ?? '',
      testId: `employee-card_${favurUuid}`,
      onClick: () => history.push(`${routes.employeeCard}?person=${favurUuid}`),
    }))
