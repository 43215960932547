import React from 'react'
import { Typography } from '@mui/material'
import { CloseIcon } from '../Icon'
import { ICON_SIZE } from '../Icon/constants'
import { Chip as MuiChip } from './styles'
import type { ChipProps } from './types'

const Chip: React.FC<ChipProps> = (props) => {
  const { selected, label } = props

  return (
    <MuiChip
      {...props}
      size="medium"
      label={<Typography variant="body1">{label}</Typography>}
      variant={selected ? 'filled' : 'outlined'}
      deleteIcon={<CloseIcon size={ICON_SIZE.small} />}
    />
  )
}

export default Chip
