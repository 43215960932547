import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'
import { typographyVariants } from 'redesign/themes/main/typography'
import { buttonColorOptions } from './constants'

const getVariants: (theme: Theme) => ComponentsVariants['MuiButton'] = (theme) =>
  buttonColorOptions.flatMap((option) => [
    {
      props: { disabled: true, color: option, variant: 'contained' },
      style: {
        opacity: 0.5,
        '&.Mui-disabled': {
          backgroundColor: theme.palette[option].main,
          color: theme.palette.common.white,
        },
        '> .MuiLoadingButton-loadingIndicator': {
          color: theme.palette.common.white,
        },
      },
    },
    {
      props: { disabled: true, color: option, variant: 'outlined' },
      style: {
        opacity: 0.5,
        '&.Mui-disabled': {
          color: theme.palette[option].main,
          // The 80 at the end of the boder color definition means that this will have 50% opacity.
          // It is here to match the default MUI button styling for a disabled button.
          border: `${theme.spacing(0.125)} solid ${theme.palette[option].main}80`,
        },
        '> .MuiLoadingButton-loadingIndicator': {
          color: theme.palette[option].main,
        },
      },
    },
    {
      props: { color: 'inherit', variant: 'outlined' }, // Special case for inherit
      style: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.secondary,
      },
    },
    {
      props: { disabled: true, color: 'inherit', variant: 'outlined' }, // Special case for inherit
      style: {
        opacity: 0.5,
        '&.Mui-disabled': {
          color: theme.palette.text.primary,
          borderColor: theme.palette.text.secondary,
        },
        '> .MuiLoadingButton-loadingIndicator': {
          color: theme.palette.text.primary,
        },
      },
    },
    {
      props: { disabled: true, color: option, variant: 'text' },
      style: {
        opacity: 0.5,
        '&.Mui-disabled': {
          color: theme.palette[option].main,
        },
        '> .MuiLoadingButton-loadingIndicator': {
          color: theme.palette[option].main,
        },
      },
    },
  ])

const styleOverrides: ComponentsOverrides<Theme>['MuiButton'] = {
  root: ({ theme }) => ({
    borderRadius: '0.5rem',
    opacity: 1,
    minWidth: 'auto',
    '&.MuiLoadingButton-loading': {
      opacity: 1,
    },
    variants: getVariants(theme),
  }),
  sizeLarge: {
    ...typographyVariants.buttonMedium,
    textTransform: 'none',
    padding: '0.625rem 1rem',
  },
  sizeMedium: {
    ...typographyVariants.buttonSmall,
    textTransform: 'none',
    padding: '0.5rem 0.75rem',
  },
  sizeSmall: {
    ...typographyVariants.buttonSmall,
    textTransform: 'none',
    padding: '0.375rem 0.5rem',
  },
}
const MuiButton = {
  styleOverrides,
}

export default MuiButton
