import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { CalendarNoDataIcon } from 'redesign/components/atoms/Icon'
import { NoShiftContainer } from './styles'

const NoShifts = () => {
  const theme = useTheme()

  return (
    <NoShiftContainer>
      <CalendarNoDataIcon color={theme.palette.text.secondary} />
      <Typography variant="body2" color={theme.palette.text.secondary}>
        No Shift
      </Typography>
    </NoShiftContainer>
  )
}

export default NoShifts
