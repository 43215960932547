import React from 'react'
import { useTheme } from '@mui/material'
import { Container, Title } from './styles'
import type { IconAndTextBlockProps } from './types'

const IconAndTextBlock: React.FC<IconAndTextBlockProps> = ({
  overflowSingleLine = false,
  icon,
  title,
  variant = 'subtitle1',
  gap,
  color,
}) => {
  const theme = useTheme()

  return (
    <Container gap={gap}>
      {icon}
      <Title overflowSingleLine={overflowSingleLine} color={color ?? theme.palette.text.primary} variant={variant}>
        {title}
      </Title>
    </Container>
  )
}

export default IconAndTextBlock
