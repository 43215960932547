import { Locale } from 'date-fns'
import { TFunction } from 'react-i18next'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { FormatDateRange } from 'redesign/shared/date/types'
import { formatDateRange } from 'redesign/shared/date/utils'
import { CmsContentNotification } from 'shared/graphql/graphql'
import { CONTENT_TYPES } from 'types/cms'
import { newDateWithoutTime } from 'utils/date'

const formatCmsDateNotification = ({ dates, locale, t }: Omit<FormatDateRange, 'dateFormat'>) => {
  const dateFormat = DATE_FORMAT_VARIANTS.longWithoutWeekday
  const { startDate, endDate } = dates

  if (!startDate || !endDate) return ''

  return formatDateRange({ dates, dateFormat, locale, t })
}

export const contentTranslationPropsFromNotification = (
  data: CmsContentNotification,
  t: TFunction<'translation', undefined>,
  locale: Locale | undefined,
) => ({
  title: data.title,
  date: formatCmsDateNotification({
    dates: {
      startDate: newDateWithoutTime(data?.startDate),
      endDate: newDateWithoutTime(data?.endDate),
    },
    t,
    locale,
  }),
})

const getPageTranslations = ({ isNew }: { isNew: boolean }) => {
  if (isNew) {
    return {
      title: `notification.new.cms.new.page.inapp.title`,
      body: `notification.new.cms.new.page.inapp.body`,
    }
  }

  return {
    title: `notification.new.cms.update.page.title`,
    body: `notification.new.cms.update.page.inapp.body`,
  }
}

const getEventTranslations = ({ isNew }: { isNew: boolean }) => {
  if (isNew) {
    return {
      title: `notification.new.cms.new.event.inapp.title`,
      body: `notification.new.cms.new.event.inapp.body`,
    }
  }

  return {
    title: `notification.new.cms.update.event.inapp.title`,
    body: `notification.new.cms.update.event.inapp.body`,
  }
}

const getNewsletterTranslations = () => {
  return {
    title: `notification.new.cms.new.newsletter.inapp.title`,
    body: `notification.new.cms.new.newsletter.inapp.body`,
  }
}

export const getTranslations = ({ contentType, isNew }: { contentType: string; isNew: boolean }) => {
  switch (contentType) {
    case CONTENT_TYPES.page:
      return getPageTranslations({ isNew })
    case CONTENT_TYPES.event:
      return getEventTranslations({ isNew })
    case CONTENT_TYPES.newsletter:
      return getNewsletterTranslations()
    default:
      return getPageTranslations({ isNew })
  }
}
