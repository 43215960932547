import { styled } from '@mui/material'

export const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  & > *:last-child {
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }
`

export const EmptyContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
`
