import { useMemo } from 'react'
import { prepareShowingShifts } from 'components/ShiftsList/components/utils'
import { PlannedShiftT } from 'types'
import { getEventsFromTenantsAndOwners } from 'utils/cms'
import { TShifts } from './types'

export const useShiftTagList = ({ tenants, shiftUpdates, events }: TShifts) => {
  const tenantsShifts = useMemo(() => tenants.flatMap(({ shifts }) => shifts || []), [tenants])

  const hasUnacknowledgedTask = useMemo(() => {
    const unacknowledgedTaskIds = shiftUpdates
      .filter((task) => !task.taskData.acknowledgedAt)
      .map((task) => `${task.id}`)

    return tenantsShifts.filter((shift) => unacknowledgedTaskIds.includes(`${shift?.updateTaskId}`)).length > 0
  }, [shiftUpdates, tenantsShifts])

  const filteredEvents = useMemo(() => {
    const tenantOwnerPairs = tenants.reduce<{ tenantId: string; ownerId: string }[]>(
      (acc, { tenantId, ownerId }) => (tenantId && ownerId ? [...acc, { tenantId, ownerId }] : acc),
      [],
    )

    if (!tenantOwnerPairs.length) return events

    return getEventsFromTenantsAndOwners(events, tenantOwnerPairs)
  }, [events, tenants])

  const showFootnote = useMemo(
    () => tenantsShifts.find((shift) => shift?.isManual === true) && !hasUnacknowledgedTask,
    [hasUnacknowledgedTask, tenantsShifts],
  )

  const showingShifts = useMemo(() => prepareShowingShifts((tenantsShifts as unknown) as PlannedShiftT[]), [
    tenantsShifts,
  ])

  return {
    showFootnote,
    showingShifts,
    filteredEvents,
    hasUnacknowledgedTask,
  }
}
