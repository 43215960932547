import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import {
  ShareUserDataCommentForFrontlinerNotification,
  ShareUserDataCommentForManagerNotification,
} from 'shared/graphql/graphql'
import { NotificationWithManagerProps } from '../types'
import { getBadgeGlossaryKey } from '../utils'
import { notificationGlosssaryKeys } from './constants'

export const getShareUserDataComment = ({ notification, t, isManager }: NotificationWithManagerProps) => {
  const data = notification.data as
    | ShareUserDataCommentForManagerNotification
    | ShareUserDataCommentForFrontlinerNotification
  const { commentShort, badge, taskUuid } = data

  const { title, body } = isManager
    ? notificationGlosssaryKeys.shareUserDataCommentForManager
    : notificationGlosssaryKeys.shareUserDataCommentForFrontliner

  return {
    title: t(title, { ...data }),
    body: `${t(body)} ${commentShort ?? t(getBadgeGlossaryKey(badge ?? ''))}`,
    route: `${routes.userDataDetails}/${taskUuid}`,
    role: isManager ? ROLE_VIEWS.office : ROLE_VIEWS.frontliner,
  }
}
