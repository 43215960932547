import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Headline from 'redesign/components/atoms/Headline'
import ShiftsCard from '../../ShiftsCard'
import { CardsContainer } from './styles'
import type { ShiftsSectionProps } from './types'

const ShiftsSection: React.FC<ShiftsSectionProps> = ({ firstDay, secondDay }) => {
  const { t, locale } = useFavurTranslation()

  if (!locale) return null

  return (
    <div>
      <Headline horizontalSpacing={0} text={t('page.dashboard.content.nextShiftHeader')} testId="show-all-shifts" />
      <CardsContainer>
        <ShiftsCard {...firstDay} locale={locale} />
        <ShiftsCard {...secondDay} locale={locale} />
      </CardsContainer>
    </div>
  )
}

export default ShiftsSection
