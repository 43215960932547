import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import SearchInput from 'redesign/components/atoms/SearchInput'
import HeaderBlock from 'redesign/components/molecules/HeaderBlock'
import { getSortingLastName } from 'utils/person'
import { CardContainer, Checkbox, DrawerBody, DrawerContainer, SearchbarContainer } from './styles'
import type { DrawerProps } from './types'
import usePersonFilterSection from './usePersonFilterSection'

const PersonFilterSelection: React.FC<DrawerProps> = ({ persons }) => {
  const { t } = useFavurTranslation()
  const { setSearchText, filteredPersonsList, onClick, currentSelectedPersons } = usePersonFilterSection(persons)

  return (
    <DrawerContainer>
      <SearchbarContainer>
        <SearchInput label={t('filters.persons.search.label')} onChange={(e) => setSearchText(e.target.value)} />
      </SearchbarContainer>
      <DrawerBody>
        {filteredPersonsList &&
          filteredPersonsList.map((option) => (
            <CardContainer key={option.id}>
              <HeaderBlock
                component={
                  <Checkbox
                    size="small"
                    onClick={() => onClick(option)}
                    defaultChecked={currentSelectedPersons.includes(option.id as number)}
                  />
                }
                title={getSortingLastName(option)}
              />
            </CardContainer>
          ))}
      </DrawerBody>
    </DrawerContainer>
  )
}

export default PersonFilterSelection
