/* eslint-disable fp/no-this */
import React, { useEffect, useState } from 'react'
import { TENANT_CONNECTION_STATUS } from 'constants/highSecurityConnection'
import useFavurForm from 'hooks/useFavurForm'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useTenantsAndPermissions from 'hooks/useTenantsAndPermissions'
import * as yup from 'yup'
import { useQuery } from '@apollo/client'
import { hasPartnerAndEmpty, validSocialInsuranceNumber } from '../helpers'
import { familyDataQuery } from './queries'
import type { FamilyDataResponse, FamilyFormValuesT } from './types'

interface IUseFamilyFormProps {
  onSubmit: (data: FamilyFormValuesT, e: React.MouseEvent<HTMLButtonElement, MouseEvent>, callback?: () => void) => void
}

const useFamilyForm = ({ onSubmit }: IUseFamilyFormProps) => {
  const { t } = useFavurTranslation()
  const requiredFieldText = t('personalData.error.requiredField')
  const [loading, setLoading] = useState<boolean>(true)
  const [formReady, setFormReady] = useState(false)
  const [apiData, setApiData] = useState<FamilyDataResponse>()
  const [defaultValues, setDefaultValues] = useState<FamilyFormValuesT>()
  const { tenantsAndPermissions } = useTenantsAndPermissions()

  const hasOneHighSecurityConnection = tenantsAndPermissions?.some(
    (tenant) => tenant.tenantConnectionStatus === TENANT_CONNECTION_STATUS.high,
  )

  const { refetch } = useQuery<{ familyData: FamilyDataResponse }>(familyDataQuery, {
    onCompleted(data) {
      setLoading(false)
      setApiData(data.familyData)
    },
  })

  const childFields = yup.object().shape({
    birthDate: yup.string().required(requiredFieldText),
    firstName: yup.string().required(requiredFieldText),
    lastName: yup.string().required(requiredFieldText),
    gender: yup.string().required(requiredFieldText),
    socialInsuranceNumber: yup.mixed().test({
      name: 'socialInsuranceNumberValid',
      test: function test() {
        const { socialInsuranceNumber } = this.parent
        if (socialInsuranceNumber) {
          const errorKey = validSocialInsuranceNumber(socialInsuranceNumber)
          return errorKey ? this.createError({ message: t(errorKey), path: 'socialInsuranceNumber' }) : true
        }
        return true
      },
    }),
  })

  const [formFields] = useState([
    {
      name: 'civilStatus',
      init: '',
      validation: yup.string().nullable().required(requiredFieldText),
    },
    {
      name: 'civilStatusSince',
      init: '',
      validation: yup.string().nullable(),
    },
    {
      name: 'kidsNumber',
      init: '',
      validation: yup.string().required(requiredFieldText),
    },
    {
      name: 'partnerFirstName',
      init: '',
      label: t('personalData.firstName.label'),
      validation: yup.mixed().test({
        name: 'partnerFirstName',
        test: function test() {
          const { civilStatus, partnerFirstName } = this.parent
          return hasPartnerAndEmpty(civilStatus, partnerFirstName)
        },
        message: requiredFieldText,
      }),
    },
    {
      name: 'partnerLastName',
      init: '',
      label: t('personalData.lastName.label'),
      validation: yup.mixed().test({
        name: 'partnerLastName',
        test: function test() {
          const { civilStatus, partnerLastName } = this.parent
          return hasPartnerAndEmpty(civilStatus, partnerLastName)
        },
        message: requiredFieldText,
      }),
    },
    {
      name: 'partnerBirthDate',
      init: '',
      label: t('personalData.birthDate.label'),
      validation: yup.string().test({
        name: 'validPartnerBirthDate',
        test: function test() {
          const { civilStatus, partnerBirthDate } = this.parent
          return hasPartnerAndEmpty(civilStatus, partnerBirthDate)
        },
        message: t('personalData.error.invalidDate'),
      }),
    },
    {
      name: 'partnerGender',
      init: '',
      label: t('personalData.gender.label'),
      validation: yup.mixed().test({
        name: 'partnerGender',
        test: function test() {
          const { civilStatus, partnerGender } = this.parent
          return hasPartnerAndEmpty(civilStatus, partnerGender)
        },
        message: requiredFieldText,
      }),
    },
    {
      name: 'partnerSocialInsuranceNumber',
      init: '',
      label: t('personalData.socialInsuranceNumber.label'),
      validation: yup.mixed().test({
        name: 'socialInsuranceNumberValid',
        test: function test() {
          const { partnerSocialInsuranceNumber } = this.parent
          if (partnerSocialInsuranceNumber) {
            const errorKey = validSocialInsuranceNumber(partnerSocialInsuranceNumber)
            return errorKey ? this.createError({ message: t(errorKey), path: 'partnerSocialInsuranceNumber' }) : true
          }
          return true
        },
      }),
    },
    {
      name: 'children',
      init: [],
      validation: yup.array().of(childFields),
    },
  ])

  const { favurForm, getFormHandlers } = useFavurForm<FamilyFormValuesT>({
    context: 'personalData',
    prolongHighSecuritySession: hasOneHighSecurityConnection,
    formFields,
    onSubmit: async (data, event) => {
      onSubmit(data as FamilyFormValuesT, event as React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch)
    },
    // eslint-disable-next-line no-console
    onError: console.error,
  })

  const formHandlers = (name: keyof FamilyFormValuesT) => {
    const handlers = getFormHandlers(name)
    return {
      ...handlers,
      id: `user-employment-data_family_${name}`,
    }
  }

  const { reset } = favurForm
  useEffect(() => {
    if (!loading && apiData !== undefined) {
      const withDefault = {
        civilStatus: apiData.civilStatus,
        civilStatusSince: apiData.civilStatusSince,
        kidsNumber: apiData.children?.length || 0,
        partnerFirstName: apiData?.partner?.firstName,
        partnerLastName: apiData?.partner?.lastName,
        partnerGender: apiData?.partner?.gender,
        partnerBirthDate: apiData?.partner?.birthDate || '',
        partnerSocialInsuranceNumber: apiData?.partner?.socialInsuranceNumber || '',
        children: apiData.children,
      }

      reset(withDefault, {
        keepDirty: true,
      })
      setDefaultValues(withDefault as FamilyFormValuesT)
      setFormReady(true)
    }
  }, [loading, apiData, reset])

  return {
    favurForm,
    formHandlers,
    formReady,
    defaultValues,
  }
}

export default useFamilyForm
