import React from 'react'
import { IconContainer } from '../../molecules/NavigationGroup/styles'
import type { NavIconProps } from './types'

const NavigationIcon: React.FC<NavIconProps> = ({ icon: Icon, color, isFilled }) =>
  Icon ? (
    <IconContainer>
      <Icon color={color} isFilled={isFilled} />
    </IconContainer>
  ) : null

export default NavigationIcon
