import { styled, Checkbox as MuiCheckbox } from '@mui/material'

export const FooterContainer = styled(`div`)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`

export const BodyContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
`

export const Container = styled(`div`)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`

export const DrawerBody = styled(`div`)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  overflow-y: scroll;
`

export const DrawerFooter = styled(`div`)`
  display: flex;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-top: auto;
  bottom: 0;
  background: ${({ theme }) => theme.palette.background.default};
  border-top: ${({ theme }) => `${theme.spacing(0.0625)} solid ${theme.palette.common?.cardBorder}`};
`

export const SearchbarContainer = styled(`div`)`
  padding: ${({ theme }) => theme.spacing(0, 2)};
`
export const DrawerContainer = styled(`div`)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(3)};
  box-sizing: border-box;
`
export const Checkbox = styled(MuiCheckbox)`
  padding: 0;
  & > * {
    margin: 0;
  }
`

export const CardContainer = styled(`div`)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};
  box-sizing: border-box;
  border-bottom: ${({ theme }) => `${theme.spacing(0.0625)} solid ${theme.palette.common?.cardBorder}`};
  & > * {
    min-height: fit-content;
  }
`
