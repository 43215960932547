import React from 'react'
import { TAG_SIZE } from './constants'
import { MainContainer } from './styles'
import { TagProps } from './types'

const Tag: React.FC<TagProps> = ({
  children,
  backgroundColor,
  borderColor,
  tagSize = TAG_SIZE.large,
  noPadding = false,
  fullWidth = false,
}) => (
  <MainContainer
    noPadding={noPadding}
    tagSize={tagSize}
    borderColor={borderColor}
    backgroundColor={backgroundColor}
    label={children}
    fullWidth={fullWidth}
  />
)

export default Tag
