import useDocumentsQueryPaginated from 'pages/Documents/DocumentList/useDocumentsQueryPaginated'

export const useDocumentsList = () => {
  const { list: documents, loadMore, loading: loadingDocuments, hasMoreElements } = useDocumentsQueryPaginated()
  const showLoadMoreButton = Boolean(hasMoreElements ?? true) && Boolean(documents.length)
  const loading = loadingDocuments

  return {
    documents,
    loading,
    loadMore,
    showLoadMoreButton,
  }
}
