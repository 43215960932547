import React from 'react'
import NotificationsButton from 'redesign/components/atoms/Buttons/NotificationsButton'
import { FavurLogo } from 'redesign/components/atoms/Illustrations'
import Header from '../../Header'
import { TDashboardHeader } from './types'

const DashboardHeader: React.FC<TDashboardHeader> = ({ header }) => {
  const { hasActiveAndResignedPersons, totalNotifications } = header

  return (
    <Header
      middleContent={<FavurLogo />}
      endContent={
        hasActiveAndResignedPersons ? <NotificationsButton totalNotifications={totalNotifications} /> : undefined
      }
    />
  )
}

export default DashboardHeader
