import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { IonPickerColumn, IonPickerColumnOption } from '@ionic/react'
import type { WheelPickerColumnProps } from './types'

const WheelPickerColumn: React.FC<WheelPickerColumnProps> = ({ onChange, options, value }) => {
  const { palette } = useTheme()

  return (
    <IonPickerColumn value={value} onIonChange={(event) => onChange(event.target.value as number)}>
      {options.map((option) => (
        <IonPickerColumnOption disabled={option.disabled} key={option.value} value={option.value}>
          <Typography variant="body1" color={value === option.value ? palette.primary.dark : 'inherit'}>
            {option.label}
          </Typography>
        </IonPickerColumnOption>
      ))}
    </IonPickerColumn>
  )
}

export default WheelPickerColumn
