import { styled } from '@mui/material'

export const ShiftContainer = styled('div')`
  display: grid;
  grid-template-columns: ${({ theme }) =>
    `calc(50% - ${theme.spacing(0.5)}) calc(50% - ${theme.spacing(
      0.5,
    )})`}; // this is to take into account gap for column size
  gap: ${({ theme }) => theme.spacing(1)};
`
