import React from 'react'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import CardWidget from 'redesign/components/atoms/CardWidget'
import FramedIcon from 'redesign/components/atoms/FramedIcon'
import Headline from 'redesign/components/atoms/Headline'
import { CheckMark, ManagerTasks } from 'redesign/components/atoms/Icon'
import IconAndTextBlock from 'redesign/components/molecules/IconAndTextBlock'
import { CardsContainer } from './styles'
import type { DashboardTasksSectionProps } from './types'

const TasksSection: React.FC<DashboardTasksSectionProps> = ({ isManager, taskOnClick, taskCounts }) => {
  const { t } = useFavurTranslation()

  return (
    <div>
      <Headline horizontalSpacing={0} text={t('page.tasks.header.openTasks')} testId="show-all-tasks" />
      <CardsContainer>
        {isManager && (
          <CardWidget
            testId="manager-task-section"
            badgeNumber={taskCounts?.secondView}
            onClick={() => taskOnClick(ROLE_VIEWS.office)}
          >
            <IconAndTextBlock
              overflowSingleLine
              icon={<FramedIcon icon={ManagerTasks} />}
              title={t('page.dashboard.tasks.manager')}
            />
          </CardWidget>
        )}
        <CardWidget
          testId="frontliner-task-section"
          badgeNumber={taskCounts?.todo}
          onClick={() => taskOnClick(ROLE_VIEWS.frontliner)}
        >
          <IconAndTextBlock
            overflowSingleLine
            icon={<FramedIcon icon={CheckMark} />}
            title={t('page.dashboard.tasks.frontliner')}
          />
        </CardWidget>
      </CardsContainer>
    </div>
  )
}

export default TasksSection
