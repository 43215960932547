import React from 'react'
import { Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import BackButton from 'redesign/components/atoms/Buttons/BackButton'
import SubheaderTabs from 'redesign/components/molecules/TabBar/SubheaderTabs'
import Header from 'redesign/components/organisms/Header'
import { NotificationsHeaderProps } from './types'

const NotificationsHeader: React.FC<NotificationsHeaderProps> = ({ hasManagerView }) => {
  const { t } = useFavurTranslation()
  const headerTitle = t('page.notifications.header.title')

  return (
    <Header
      middleContent={<Typography variant="subtitle1">{headerTitle}</Typography>}
      startContent={<BackButton />}
      subheader={hasManagerView ? <SubheaderTabs /> : undefined}
    />
  )
}

export default NotificationsHeader
