import { styled } from '@mui/material'

export const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.spacing(4)};
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  width: 100%;
`
