import React, { useMemo } from 'react'
import useFilterContext from 'components/Filter/context'
import type { PersonsT } from 'components/Filter/types'
import FilterSection from 'redesign/components/organisms/Filters/FilterSection'
import PersonFilterSelection from 'redesign/components/organisms/Filters/PersonsFilter/PersonFilterSelection'
import { FiltersContainer } from './styles'

const FiltersBody: React.FC = () => {
  const { getConfiguration, getPersonsListSelected } = useFilterContext()
  const configuration = useMemo(() => getConfiguration(), [getConfiguration])
  const personsListSelected = getPersonsListSelected()

  if (personsListSelected) {
    const personListOption = configuration.find((option) => option.name === personsListSelected) as PersonsT

    return <PersonFilterSelection persons={personListOption} />
  }

  return (
    <FiltersContainer>
      {configuration.map((config) => (
        <FilterSection key={config.name} configuration={config} />
      ))}
    </FiltersContainer>
  )
}

export default FiltersBody
