import React from 'react'
import { BadgeContainer } from './styles'
import type { CardWidgetProps } from './types'

const CardWidget: React.FC<CardWidgetProps> = ({ badgeNumber, onClick, children, testId }) => (
  <BadgeContainer data-testid={testId} onClick={onClick} color="primary" badgeContent={badgeNumber}>
    {children}
  </BadgeContainer>
)

export default CardWidget
