import React, { useCallback } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useJamesMutation from 'hooks/useJamesMutation'
import { userDataStepNames } from 'pages/Tasks/ShareUserData/Forms/constants'
import { StepT } from 'types'
import { datePickersToString, newDateWithoutTime } from 'utils/date'
import { bankDataMutation } from './Bank/mutations'
import { BankDataValuesT } from './Bank/types'
import useBankDataForm from './Bank/useBankForm'
import { contactDataMutation, getAddressData } from './Contact/mutations'
import { ContactFormValuesT, ContactFieldT } from './Contact/types'
import useContactForm from './Contact/useContactForm'
import { familyDataMutation } from './Family/mutation'
import { FamilyFormValuesT, PersonFieldT } from './Family/types'
import useFamilyForm from './Family/useFamilyForm'
import { personalDataMutation } from './PersonalData/mutations'
import { personalDataQuery } from './PersonalData/queries'
import { PersonalDataApiValuesT, PersonalDataValuesT } from './PersonalData/types'
import { usePersonalDataForm } from './PersonalData/usePersonalDataForm'
import { FormsContext, IFormsContext } from './context'
import { hasPartner, getFamilyMemberData } from './helpers'

interface Props {
  children?: React.ReactNode
  includeFiles: boolean
  onUpdateBankData: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onUpdateContactData: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onUpdateFamilyData: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onUpdatePersonalData: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  step?: StepT
}

export const FormProvider: React.FC<Props> = ({
  children,
  includeFiles,
  onUpdateBankData,
  onUpdateContactData,
  onUpdateFamilyData,
  onUpdatePersonalData,
  step,
}) => {
  const { t } = useFavurTranslation()
  const [updateBankData] = useJamesMutation(bankDataMutation)
  const [updateContactDataMutation] = useJamesMutation(contactDataMutation)
  const [updateFamilyData] = useJamesMutation(familyDataMutation)
  const [updatePersonalData] = useJamesMutation(personalDataMutation)
  const onSubmitBankForm = (
    data: BankDataValuesT,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback: () => void = () => {},
  ) => {
    e?.preventDefault()
    updateBankData({ ...data })
      .then(onUpdateBankData)
      .then(callback)
  }

  const { data: personalDataApi, loading: isFetching, refetch: personalDataRefetch } = useJamesApolloQuery<{
    personalData: PersonalDataApiValuesT
  }>(personalDataQuery(includeFiles))

  const onSubmitContactForm = (
    data: ContactFormValuesT,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback: () => void = () => {},
  ) => {
    e?.preventDefault()
    const contactData = {
      email: data.email,
      addresses: `[${data.addresses.map((address: ContactFieldT) => {
        return `${getAddressData(address)},`
      })}]`,
    }

    updateContactDataMutation(contactData).then(onUpdateContactData).then(callback)
  }

  const onSubmitFamilyForm = (
    data: FamilyFormValuesT,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback: () => void = () => {},
  ) => {
    e?.preventDefault()
    const partner = hasPartner(data.civilStatus ?? '') ? getFamilyMemberData(data, 'partner') : null
    const familyChildren =
      (data.kidsNumber ?? 0) > 0
        ? `[${data?.children?.map((kid: PersonFieldT) => {
            return `${getFamilyMemberData(kid)},`
          })}]`
        : '[]'

    const familyData = {
      civilStatus: data.civilStatus,
      civilStatusSince: data?.civilStatusSince ? datePickersToString(newDateWithoutTime(data.civilStatusSince)) : '',
      partner,
      children: familyChildren,
    }
    updateFamilyData(familyData).then(onUpdateFamilyData).then(callback)
  }

  const onSubmitPersonalDataForm = (data: PersonalDataValuesT, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.preventDefault()
    updatePersonalData({
      ...data,
      residenceCategoryValidUntil: datePickersToString(data.residenceCategoryValidUntil),
      socialInsuranceNumber: data.socialInsuranceNumber || '',
    }).then(() => {
      personalDataRefetch()
      onUpdatePersonalData()
    })
  }

  const bankDataForm = useBankDataForm({ onSubmit: onSubmitBankForm })
  const contactDataForm = useContactForm({ onSubmit: onSubmitContactForm })
  const familyDataForm = useFamilyForm({ onSubmit: onSubmitFamilyForm })
  const personalDataForm = usePersonalDataForm({
    validateDocuments: includeFiles,
    onSubmit: onSubmitPersonalDataForm,
    apiData: personalDataApi?.personalData,
    isFetching,
  })

  const getAllFormsValid = useCallback(async () => {
    const validations = await Promise.all([
      bankDataForm.favurForm.trigger(),
      contactDataForm.favurForm.trigger(),
      familyDataForm.favurForm.trigger(),
      personalDataForm.favurForm.trigger(),
    ])
    if (!validations[0]) {
      return t('personalData.shareUserDataCard.error.bankData')
    } else if (!validations[1]) {
      return t('personalData.shareUserDataCard.error.contactData')
    } else if (!validations[2]) {
      return t('personalData.shareUserDataCard.error.familyData')
    } else if (!validations[3]) {
      return t('personalData.shareUserDataCard.error.personalData')
    }

    return true
  }, [bankDataForm.favurForm, contactDataForm.favurForm, familyDataForm.favurForm, personalDataForm.favurForm, t])

  const getCurrentStepSubmit = useCallback(() => {
    switch (step?.name) {
      case userDataStepNames.personal:
        return personalDataForm.favurForm.handleSubmit
      case userDataStepNames.family:
        return familyDataForm.favurForm.handleSubmit
      case userDataStepNames.contact:
        return contactDataForm.favurForm.handleSubmit
      case userDataStepNames.bank:
        return bankDataForm.favurForm.handleSubmit
      default:
        return () => {}
    }
  }, [
    bankDataForm.favurForm.handleSubmit,
    contactDataForm.favurForm.handleSubmit,
    familyDataForm.favurForm.handleSubmit,
    personalDataForm.favurForm.handleSubmit,
    step,
  ])

  const providerValue: IFormsContext = {
    getBankDataForm: () => ({ defaultValues: undefined, formReady: !bankDataForm.loading, ...bankDataForm }),
    getContactDataForm: () => contactDataForm,
    getFamilyDataForm: () => familyDataForm,
    getPersonalDataForm: () => personalDataForm,
    onPersonalDataSubmit: () => onUpdatePersonalData,
    getAllFormsValid: getAllFormsValid,
    getIncludeFiles: () => includeFiles,
    getCurrentStepSubmit,
  }

  return <FormsContext.Provider value={providerValue}>{children}</FormsContext.Provider>
}
