import React, { useMemo } from 'react'
import Tutorial from 'components/Tutorial'
import StepPage from 'components/Tutorial/StepPage'
import { TutorialStepT } from 'components/Tutorial/types'
import { FALLBACK_LANGUAGE, FRENCH, GERMAN, ITALIAN } from 'constants/i18n'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import { ConversationLock, DocumentInfo, PhoneMail, PhoneSMS } from '../../../icons'
import { ICON_SIZE_TUTORIAL } from '../../../icons/constants'
import palette from '../../../utils/theme/palette'
import { TutorialProps } from '../types'

export const resignationTenantNameKey = 'STATE_RESIGNATION_TENANT_NAME'

const SocialEngineering: React.FC<TutorialProps> = ({ tutorialName, t, step, setStep, history }: TutorialProps) => {
  const iconFillColor = palette.primary.main
  const iconSize = ICON_SIZE_TUTORIAL
  const mainTextTypographyVariant = 'body1'
  const titleTextTypographyVariant = 'h5'

  const { locale } = useFavurTranslation()

  const externalUrlDoc = useMemo(() => {
    const supportedLocales = [FRENCH.code, GERMAN.code, ITALIAN.code] // locale code for english is en-GB
    const baseUrl = 'https://www.ncsc.admin.ch/ncsc'
    const pathUrl = 'home/infos-fuer/infos-private/aktuelle-themen/social-engineering.html'

    const localeUrl = supportedLocales.includes(locale?.code || '') ? locale?.code : FALLBACK_LANGUAGE

    return `${baseUrl}/${localeUrl}/${pathUrl}`
  }, [locale])

  const goToDashboard = () => {
    history.push(routes.dashboard)
  }
  const goToExternalLink = () => {
    window.open(externalUrlDoc, '_blank')
  }

  const stepPages: TutorialStepT[] = [
    {
      key: 'step1',
      titleText: t(`tutorial.socialEngineering.step1.titleText`),
      mainText: t(`tutorial.socialEngineering.step1.text`),
      titleTextTypographyVariant,
      mainTextTypographyVariant,
      icon: <ConversationLock fill={iconFillColor} size={iconSize} />,
    },
    {
      key: 'step2',
      titleText: t(`tutorial.socialEngineering.step2.titleText`),
      mainText: t(`tutorial.socialEngineering.step2.text`),
      titleTextTypographyVariant,
      mainTextTypographyVariant,
      icon: <PhoneSMS fill={iconFillColor} size={iconSize} />,
    },
    {
      key: 'step3',
      titleText: t(`tutorial.socialEngineering.step3.titleText`),
      mainText: t(`tutorial.socialEngineering.step3.text`),
      titleTextTypographyVariant,
      mainTextTypographyVariant,
      icon: <PhoneMail fill={iconFillColor} size={iconSize} />,
    },
    {
      key: 'step4',
      titleText: t(`tutorial.socialEngineering.step4.titleText`),
      mainText: t(`tutorial.socialEngineering.step4.text`),
      titleTextTypographyVariant,
      mainTextTypographyVariant,
      close: () => goToDashboard(),
      icon: <DocumentInfo fill={iconFillColor} size={iconSize} />,
      mainAction: goToExternalLink,
      mainActionLabel: t(`tutorial.socialEngineering.step4.cta`),
      secondaryAction: goToDashboard,
      secondaryActionLabel: t(`tutorial.socialEngineering.step4.goToDashboard`),
    },
  ]

  const stepPagesComponents = stepPages.map((stepPage) => (
    <StepPage key={stepPage.key} tutorialName={tutorialName} stepProps={stepPage} />
  ))

  return (
    <Tutorial
      stepPages={stepPagesComponents}
      step={step}
      setStep={setStep}
      onSwipeEnd={() => goToDashboard()}
      startButtonTextKey="tutorial.socialEngineering.intro.start"
    />
  )
}

export default SocialEngineering
