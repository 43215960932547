import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const CloseIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  viewBox = ICON_VIEWBOX.medium,
  size = ICON_SIZE.medium,
  fillOpacity,
  ...props
}) => (
  <SvgWrapper viewBox={viewBox} size={size} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 1 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  </SvgWrapper>
)

export default CloseIcon
