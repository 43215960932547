import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const EmployeeCardIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
}) => (
  <SvgWrapper data-testid="employee-card-icon" viewBox={viewBox} size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.25 6A2.75 2.75 0 0 1 3 3.25h18A2.75 2.75 0 0 1 23.75 6v12A2.75 2.75 0 0 1 21 20.75H3A2.75 2.75 0 0 1 .25 18V6ZM3 4.75c-.69 0-1.25.56-1.25 1.25v12c0 .69.56 1.25 1.25 1.25h18c.69 0 1.25-.56 1.25-1.25V6c0-.69-.56-1.25-1.25-1.25H3Zm3.958 1.757a3.375 3.375 0 0 1 3 6.029A5.25 5.25 0 0 1 13.5 17.5a.75.75 0 0 1-.75.75h-9A.75.75 0 0 1 3 17.5a5.25 5.25 0 0 1 3.542-4.964 3.376 3.376 0 0 1 .416-6.03ZM8.25 7.75a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Zm5 .25a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Zm0 3a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm-5 2.75a3.75 3.75 0 0 0-3.674 3h7.348a3.75 3.75 0 0 0-3.674-3Z"
      fill={color}
      fillOpacity=".87"
    />
  </SvgWrapper>
)

export default EmployeeCardIcon
