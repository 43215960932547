import React from 'react'
import { Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { MenuIcon } from 'redesign/components/atoms/Icon'
import { BottomNavigation, BottomNavigationAction, Container } from './styles'
import { NavigationBarContentProps } from './types'

const NavigationBarContent: React.FC<NavigationBarContentProps> = ({
  DashboardIcon,
  mainNavigation,
  value,
  onNavItemClick,
  showSidePanel,
}) => {
  const { t } = useFavurTranslation()

  return (
    <Container>
      <BottomNavigation value={value} showLabels>
        {mainNavigation.map(({ redesignIcon: Icon, label, labelShort, to, role }, index) => {
          const icon = Icon ? <Icon isFilled={value === index} /> : <DashboardIcon />

          return (
            <BottomNavigationAction
              key={label}
              icon={icon}
              tabIndex={index}
              data-testid={`native-navigation-${label}`}
              onClick={() => onNavItemClick({ newValue: index, to, role })}
              label={<Typography variant="caption">{t(labelShort || label)}</Typography>}
              disableRipple
            />
          )
        })}
        <BottomNavigationAction
          icon={<MenuIcon />}
          onClick={showSidePanel}
          tabIndex={mainNavigation.length}
          data-testid="native-navigation-close-button"
          label={<Typography variant="caption">Menu</Typography>}
        />
      </BottomNavigation>
    </Container>
  )
}

export default NavigationBarContent
