import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { ReminderNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getReminderData = ({ notification, t }: GetNotificationData) => {
  const { taskUuid } = notification.data as ReminderNotification

  return {
    title: t(notificationGlosssaryKeys.reminder.title),
    body: t(notificationGlosssaryKeys.reminder.body),
    route: taskUuid ? routes.monthlySheetsWithTask(taskUuid) : '',
    role: ROLE_VIEWS.frontliner,
  }
}
