import { Card, styled } from '@mui/material'

export const MuiCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isNew' && prop !== 'isClickable' && prop !== 'isNative',
})<{ isNew: boolean; isClickable?: boolean; isNative?: boolean }>`
  border-radius: 0;
  background-color: ${({ isNew, theme }) =>
    isNew ? theme.palette.common.newCard : theme.palette.common.backgroundCard};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  ${({ isNative, theme }) =>
    !isNative &&
    `
      :hover {
        background-color: ${theme.palette.common.hoverCard};
      }
    `}
`

export const Content = styled('div')`
  text-decoration: none;
`
