import React from 'react'
import {
  MiddleContainer,
  HeaderContainer,
  EndContainer,
  StartContainer,
  MainContainer,
  HeaderContent,
  DividerLine,
} from './styles'
import type { HeaderProps } from './types'

const Header: React.FC<HeaderProps> = ({
  startContent,
  middleContent,
  endContent,
  subheader,
  withoutDivider = false,
}) => {
  return (
    <MainContainer>
      <HeaderContainer>
        <HeaderContent>
          <StartContainer>{startContent}</StartContainer>
          <MiddleContainer>{middleContent}</MiddleContainer>
          <EndContainer>{endContent}</EndContainer>
        </HeaderContent>
      </HeaderContainer>
      {subheader}
      {!withoutDivider && <DividerLine />}
    </MainContainer>
  )
}

export default Header
