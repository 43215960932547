import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const FavurBirdWhite: React.FC<IconProps> = ({ viewBox = ICON_VIEWBOX.medium, size = ICON_SIZE.medium }) => (
  <SvgWrapper viewBox={viewBox} size={size}>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.112 8.492V4.431h4.061l-4.061 4.061Z" fill="#FF0080" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.112 6.277V4.43h1.846l-1.846 1.846Z" fill="#FFC300" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.111 4.43 9.681 0 5.25 4.43V24l8.861-8.861V4.43Z"
      fill="#E6EAF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.719 22.892-1.233-4.6-.357.095 1.208 4.505H9.68v.37h5.169v-.37h-3.131Z"
      fill="#E6EAF4"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.111 15.139V6.277L5.25 15.138V24l8.861-8.861Z" fill="#8EA1C3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.635 7.754 5.25 15.139v6.646l7.385-7.385V7.754Z" fill="#1F3867" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.527 5.17a1.846 1.846 0 1 1-3.692 0 1.846 1.846 0 0 1 3.692 0Z"
      fill="#7187AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.696 5.17a1.015 1.015 0 1 1-2.03 0 1.015 1.015 0 0 1 2.03 0Z"
      fill="#141A33"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.392 4.474-.696.695H9.681l1.305-1.305c.173.173.312.38.406.61Z"
      fill="#fff"
    />
  </SvgWrapper>
)

export default FavurBirdWhite
