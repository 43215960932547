import React from 'react'
import { useTheme } from '@mui/material'
import DotDivider from 'redesign/components/atoms/Divider/DotDivider'
import { MainContainer, Container, StartText, EndText } from './styles'
import { ContentFooterProps } from './types'

const ContentFooter: React.FC<ContentFooterProps> = ({
  startText,
  secondaryText,
  endText,
  mainTextVariant = 'body2',
  endTextVariant = 'body2',
  primaryColorVariant,
  secondaryColorVariant,
}) => {
  const theme = useTheme()
  const primaryColor = primaryColorVariant ?? theme.palette.text.primary

  return (
    <MainContainer>
      <Container>
        {startText && (
          <StartText color={primaryColor} variant={mainTextVariant}>
            {startText}
          </StartText>
        )}
        {secondaryText && (
          <Container>
            <DotDivider />
            <EndText color={primaryColor} variant={mainTextVariant}>
              {secondaryText}
            </EndText>
          </Container>
        )}
      </Container>
      {endText && (
        <EndText color={secondaryColorVariant ?? theme.palette.text.secondary} variant={endTextVariant}>
          {endText}
        </EndText>
      )}
    </MainContainer>
  )
}

export default ContentFooter
