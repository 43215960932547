import React, { useCallback } from 'react'
import useFilterContext from 'components/Filter/context'
import MultipleChoiceChipGroup from 'redesign/components/molecules/ChipGroups/MultipleChoiceChipGroup'
import type { CheckListProps } from './types'

const CheckListFilter: React.FC<CheckListProps> = ({ configuration }) => {
  const { toggleOption, isCheckedOption } = useFilterContext()
  const { name: configurationName, options } = configuration

  const onSelect = useCallback((name: string) => toggleOption(configurationName, name), [
    configurationName,
    toggleOption,
  ])
  const isChecked = useCallback((name: string) => isCheckedOption(configurationName, name), [
    configurationName,
    isCheckedOption,
  ])

  return <MultipleChoiceChipGroup options={options} onSelect={onSelect} isChecked={isChecked} />
}

export default CheckListFilter
